import { get } from "@/actions/setting.action";
import React, { useEffect } from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const dispatch = useDispatch();
  const { currentSetting: settingsFaqs, status } = useSelector(
    (state) => state.settings
  );

  useEffect(() => {
    if (status === "idle") {
      dispatch(get("faqs"));
    }
  });

  console.log(settingsFaqs ?? "");

  return (
    <div className="min-h-screen bg-gradient-to-br flex flex-col gap-4 items-center justify-center mt-8">
      <div className="bg-gray-100 shadow-xl rounded-lg p-8 w-full max-w-3xl">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Bizimle İletişime Geçin
        </h1>
        <p className="text-center text-gray-600 mb-8">
          Herhangi bir sorunuz varsa aşağıdaki formu doldurun
          <br />
          veya bizimle iletişime geçmek için detayları kullanın.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* İletişim Bilgileri */}
          <div className="space-y-6">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-red-600 text-2xl mr-4" />
              <div>
                <h3 className="text-lg font-medium text-secondary">Adres</h3>
                <p className="text-gray-600">
                  Fethiye Mh. Sanayi Cd. CornerPlus İş Merkezi No:263/A
                  Nilüfer/BURSA
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <FaPhone className="text-red-600 text-2xl mr-4" />
              <div>
                <h3 className="text-lg font-medium text-secondary">Telefon</h3>
                <p className="text-gray-600">
                  +90 (224) 234 02 37 - (538) 605 83 30
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-red-600 text-2xl mr-4" />
              <div>
                <h3 className="text-lg font-medium text-secondary">E-posta</h3>
                <p className="text-gray-600">info@saymedya.com</p>
              </div>
            </div>
          </div>

          {/* Form */}
          <form className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-secondary"
              >
                Adınız
              </label>
              <input
                type="text"
                id="name"
                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Adınızı girin"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm  font-medium text-secondary"
              >
                E-posta
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full  p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="E-posta adresinizi girin"
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-secondary"
              >
                Mesajınız
              </label>
              <textarea
                id="message"
                rows="4"
                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Mesajınızı yazın"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-200"
            >
              Gönder
            </button>
          </form>
        </div>
      </div>
      <div className="container mx-auto mt-16">
        <iframe
          title="google-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3045.991934089201!2d28.97964717639525!3d40.231481566892796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca15006b5d4de3%3A0x46b7d07ab2c52ff8!2sCorner%20Plus%20%C4%B0%C5%9F%20Merkezi!5e0!3m2!1str!2str!4v1733729490231!5m2!1str!2str"
          className="w-full h-64 rounded"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="container mx-auto mt-16">
        <section className="text-gray-700">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                Sıkça Sorulan Sorular
              </h1>
              <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                İşimizle ilgili bize sıkça sorulan soruları ve size nasıl
                yardımcı olabiliceğimizi cevaplayalım.
              </p>
            </div>

            <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-4 ">
              {settingsFaqs?.value?.length > 0 &&
                settingsFaqs?.value?.map((faq, index) => (
                  <details key={faq._id} className="mb-4">
                    <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                      {faq.question}
                    </summary>

                    <span>{faq.question}</span>
                  </details>
                ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
