import settingService from "@/services/settings.service";
import { setError, setMessage } from "./message";

export const create = (payload: any) => async (dispatch: any) => {
  await settingService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: "CREATE_SETTING_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "CREATE_SETTING_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (distpach: any) => {
  await settingService
    .getAll()
    .then((response: any) => {
      distpach({ type: "FETCH_SETTINGS_SUCCESS", payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      distpach({ type: "FETCH_SETTINGS_FAILED" });
      distpach(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (key: string) => async (dispatch: any) => {
  await settingService
    .get(key)
    .then((response: any) => {
      dispatch({ type: "FETCH_SETTING_SUCCESS", payload: response.data });
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "FETCH_SETTING_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update = (key: string, payload: any) => async (dispatch: any) => {
  await settingService
    .update(key, payload)
    .then((response: any) => {
      dispatch({ type: "UPDATE_SETTING_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_SETTING_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (key: string) => async (dispatch: any) => {
  await settingService
    .remove(key)
    .then((response: any) => {
      dispatch({ type: "REMOVE_SETTING_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "REMOVE_SETTING_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
