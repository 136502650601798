import { CvDto } from "@/data/dtos/cv.dto";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiClient } from "./base.service";
import { logger } from "@/lib/default-logger";

interface PayloadInterface {
  email: string;
  password: string;
  name: string;
  role: string;
}

interface LoginPayloadInterface {
  email: string;
  password: string;
}

const logout = () => {
  localStorage.setItem("rememberMe", "false");
  localStorage.removeItem("user");
  window.location.href = "/";
};

const authService = {
  register: async (payload: PayloadInterface) =>
    await apiClient.post("/user/register", payload),
  login: async (payload: LoginPayloadInterface) =>
    await apiClient.post("/auth/login", payload),
  logout: async (sessionId: string) => {
    await apiClient.post("/sessions/end", sessionId);
    logout();
  },
  forgotPassword: async (email: string) =>
    await apiClient.post("/auth/forgot-password", email),
  resetPassword: async (payload: any) =>
    await apiClient.post("/auth/reset-password", payload),
  updateState: async (token: string) => {
    return await apiClient.post("/auth/state", {});
  },
  createCv: async (payload: CvDto) => await apiClient.post("/cvs", payload),
  updateCv: async (id: string, payload: CvDto) =>
    await apiClient.patch("/cvs/" + id, payload),
  removeCv: async (id: string) => await apiClient.delete("/cvs/" + id),
  updateCourseProgress: async (
    userId: string,
    courseId: string,
    payload: any
  ) =>
    await apiClient.patch(
      "/user-course-progresses/update-course-progress/" +
        userId +
        "/" +
        courseId,
      payload
    ),
  markResourceAsComplated: async (
    userId: string,
    courseId: string,
    sectionId: string,
    resourceId: string,
    payload: any
  ) => {
    const response = await apiClient.patch(
      "/user-course-progresses/mark-resource-as-completed/" +
        userId +
        "/" +
        courseId +
        "/" +
        sectionId +
        "/" +
        resourceId,
      payload
    );

    console.log(response);
    return response;
  },
  checkSectionCompletion: async (
    userId: string,
    courseId: string,
    sectionId: string,
    payload: any
  ) =>
    await apiClient.patch(
      "/user-course-progresses/mark-section-Completion/" +
        userId +
        "/" +
        courseId +
        "/" +
        sectionId,
      payload
    ),
};

export default authService;
