interface WhisListState {
  items: any[];
  status: string;
}

const initialState: WhisListState = {
  items: [],
  status: "idle",
};

export default function whisListReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "ADD_TO_WHIST_LIST":
      console.log(payload);
      if (!state.items.some((item) => item._id === payload._id)) {
        return {
          ...state,
          items: [...state.items, payload],
          status: "success",
        };
      }
      return state;

    case "REMOVE_FROM_WHIST_LIST":
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload._id),
        status: "success",
      };

    case "CLEAR_WHIST_LIST":
      console.log("test");
      return {
        ...state,
        items: [],
        status: "success",
      };
    default:
      return state;
  }
}
