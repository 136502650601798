import { Link, Outlet } from "react-router-dom";
import ApplicationLogo from "../../../../components/core/ApplicationLogo";
import { url } from "../../../../utils";
import React from "react";
import Nav from "../../layouts/Nav";

export default function AuthLayout() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center px-4 sm:px-0">
      <Nav />
      {/* Application Logo */}
      <div className="mb-6 w-32">
        <Link to={url("Home")}>
          <ApplicationLogo className="w-20 h-20 fill-current text-indigo-600" />
        </Link>
      </div>

      {/* Auth Form Container */}
      <div className="w-full max-w-md p-6 rounded-lg">
        {/* Fallback Boundary */}
        <React.Suspense
          fallback={
            <div className="text-center">
              <span className="text-gray-500">Yükleniyor...</span>
            </div>
          }
        >
          <Outlet />
        </React.Suspense>
      </div>

      {/* Footer Links */}
      <div className="mt-4 text-center">
        <p className="text-gray-600 text-sm">
          <span>Hesabınız yok mu?</span>{" "}
          <Link
            to={url("Auth.Register")}
            className="text-indigo-600 hover:underline"
          >
            Kayıt Ol
          </Link>
        </p>
      </div>
    </div>
  );
}
