import React, { useRef, useEffect, useState } from "react";

interface VideoComponentProps {
  currentResource: any;
  onComplete?: (resourceId: number) => void; // onComplete opsiyonel
}

export default function VideoComponent({
  currentResource,
  onComplete,
}: VideoComponentProps) {
  const videoRef = useRef(null);
  const [hasCompleted, setHasCompleted] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current! as HTMLVideoElement;

    if (!videoElement) return;

    const handleTimeUpdate = () => {
      const completionPercentage = 0.95;
      if (
        !hasCompleted &&
        videoElement.currentTime / videoElement.duration >= completionPercentage
      ) {
        console.log(videoElement.currentTime);
        setHasCompleted(true);
        if (onComplete) onComplete(100);
      }
    };

    videoElement.addEventListener("timeupdate", handleTimeUpdate, {
      passive: true,
    });

    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [hasCompleted, onComplete]);

  return (
    <video
      ref={videoRef}
      controls
      className="w-full h-[70vh]"
      src={currentResource?.video?.url}
      title={currentResource?.title}
    >
      Tarayıcınız bu videoyu oynatmayı desteklemiyor.
    </video>
  );
}
