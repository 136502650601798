import axios from "axios";

const settingService = {
  create: async (payload: any) =>
    await axios.post(process.env.REACT_APP_API_BASE_URL + "/settings", payload),
  getAll: async () =>
    await axios.get(process.env.REACT_APP_API_BASE_URL + "/settings"),
  get: async (key: string) =>
    await axios.get(process.env.REACT_APP_API_BASE_URL + "/settings/" + key),
  update: async (key: string, payload: any) =>
    await axios.patch(
      process.env.REACT_APP_API_BASE_URL + "/settings/" + key,
      payload
    ),
  remove: async (key: string) =>
    await axios.delete(process.env.REACT_APP_API_BASE_URL + "/settings/" + key),
};

export default settingService;
