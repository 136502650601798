import { get } from "@/actions/agreements.action";
import { getAll } from "@/actions/setting.action";
import { logger } from "@/lib/default-logger";
import { replacePlaceholders } from "@/utils";
import { Modal, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function PrivacyModal({
  isOpen,
  setIsOpen,
  agreementId,
  setContent,
}) {
  const dispatch = useDispatch();
  const { agreement } = useSelector((state: any) => state.agreements);
  const [currentAgreement, setCurrentAgreement] = useState<any>(null);
  const { settings } = useSelector((state: any) => state.settings);
  const [compInfo, setCompInfo] = useState<any>(null);

  const dynamicContent = replacePlaceholders(currentAgreement?.content ?? "", {
    companyName: compInfo?.name ?? "Firma Adı",
    compEmail: compInfo?.email ?? "Firma Email",
    companyTitle: compInfo?.title ?? "Firma Ünvanı",
    host: window.location.host,
    companyKvkkEmail: compInfo?.kvkkEmail ?? "Firma KVKK Email",
    companyAddress: compInfo?.address ?? "Firma Adresi",
  });
  console.log("id", agreementId);

  useEffect(() => {
    dispatch(get(agreementId) as any);
  }, [dispatch, agreementId]);

  useEffect(() => {
    if (settings?.length <= 0) {
      dispatch(getAll() as any);
    }
  }, [dispatch]);

  useEffect(() => {
    if (agreement) {
      setCurrentAgreement(agreement);
    }
  }, [agreement]);

  useEffect(() => {
    const companyInfoSetting = settings.find(
      (setting: { key: string }) => setting.key === "companyInfo"
    );
    console.log("comp info", companyInfoSetting);
    if (companyInfoSetting) {
      setCompInfo(companyInfoSetting.value[0]);
    }
  }, [settings]);

  useEffect(() => {
    setContent(dynamicContent);
  }, [dynamicContent]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="mx-auto bg-white rounded container shadow my-8 overflow-y-scroll max-h-[90vh]">
        <Typography variant="h6" className="text-center p-4">
          {agreement?.title}
        </Typography>
        <div
          className="px-4 py-2 "
          dangerouslySetInnerHTML={{ __html: dynamicContent }}
        />
      </div>
    </Modal>
  );
}
