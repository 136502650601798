import React, { useEffect, useState } from "react";
import Table from "../../../../../components/core/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAll, remove } from "../../../../../actions/courses.actions";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../../../../utils";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CourseDto } from "@/data/dtos/course.dto";
import { render } from "react-dom";
import { CourseStatus } from "@/data/enums/course-status.enum";
import { Stack, Typography } from "@mui/material";
import OptionsMenu from "@/components/core/TableOptionMenu";

export default function CourseList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const allCourses = useSelector((state: any) => state.courses);
  const { user } = useSelector((state: any) => state.auth);
  const [coursesByInstructor, setCoursesByInstructor] = useState([]);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  useEffect(() => {
    console.log("useEffect 1");
    if (allCourses.listStatus === "idle") {
      dispatch(getAll() as any);
    }
    if (allCourses?.courses?.length > 0) {
      setCoursesByInstructor(
        allCourses?.courses?.filter((course: any) =>
          course.instructors?.some((instructor: any) => {
            if (instructor._id) {
              return instructor._id === user._id;
            } else {
              return instructor === user._id;
            }
          })
        )
      );
    }
  }, [dispatch, allCourses, user._id]);

  if (coursesByInstructor.length > 0) {
    const columns: GridColDef[] = [
      {
        field: "no",
        headerName: "No",
        flex: 0.1,
      },
      {
        field: "thumbnail",
        headerName: "Eğitim Görseli",
        flex: 0.2,
        renderCell: (params) => (
          <img
            src={params.value}
            alt={params.row.title}
            style={{ width: "100px", height: "100px" }}
          />
        ),
      },
      { field: "title", headerName: "Eğitim Adı", flex: 0.5 },
      {
        field: "categories",
        headerName: "Kategoriler",
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {params.row.categories.map((category: any) => (
              <span key={category._id} style={{ marginRight: "5px" }}>
                {category.name}
              </span>
            ))}
          </div>
        ),
      },
      {
        field: "saleStart",
        headerName: "Satış Baslangıc Tarihi",
        flex: 0.3,
        renderCell: (params) => {
          console.log(params);
          return (
            <span>
              {params.row.saleStart
                ? new Date(params.row.saleStart).toLocaleDateString("tr-TR")
                : ""}
            </span>
          );
        },
      },
      {
        field: "status",
        headerName: "Eğitim Durumu",
        flex: 0.2,
        renderCell: (params) => {
          switch (params.value) {
            case CourseStatus.ARCHIVED:
              return (
                <span className="bg-red-600 text-white p-2 rounded-md">
                  Arşivlendi
                </span>
              );
            case CourseStatus.PUBLISHED:
              return (
                <span className="bg-green-600 text-white p-2 rounded-md">
                  Yayında
                </span>
              );
            case CourseStatus.DRAFT:
              return (
                <span className="bg-gray-600 text-white p-2 rounded-md">
                  Taslak
                </span>
              );

            case CourseStatus.REJECTED:
              return (
                <span className="bg-red-600 text-white p-2 rounded-md">
                  Reddedildi
                </span>
              );

            default:
              break;
          }
        },
      },
      {
        field: "options",
        headerName: "Options",
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation(); // Prevent row selection on click
            // You can manage the menu state in the parent or directly in the component
          };

          return (
            <Stack direction="row" spacing={1}>
              <OptionsMenu
                id={params.row.id}
                onEdit={() => {
                  navigation(url("Admin.Courses.Edit", { id: params.row.id }));
                }}
                onDelete={handleDelete}
                onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
              />
            </Stack>
          );
        },
      },
    ];

    const pageSizeOptions = [25, 50, 100];

    const rows = coursesByInstructor.map((item: CourseDto, index) => ({
      id: item._id,
      no: index + 1, // Her satıra benzersiz bir ID
      title: item.title,
      saleStart: item.startDate,
      level: item.level,
      price: item.price,
      discount: item.price * (item.discountPercentage / 100),
      tax: item.tax,
      status: item.status,
      isOnSale: item.isOnSale,
      thumbnail: item.images?.[0].url,
      categories: item.categories,
    }));

    return (
      <div className="bg-white rounded shadow p-2">
        <Typography className="my-2">Eğitimler</Typography>
        <div style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={pageSizeOptions}
            checkboxSelection
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
              },
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="text-center p-4 bg-yellow-300  rounded shadow">
      <span className="font-bold text-lg text-gray-600">Eğitim Bulunamadı</span>
      <br />
      <Link to={url("Admin.Courses.Create")}>Eğitim Oluştur</Link>
    </div>
  );
}
