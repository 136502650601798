import { OrderData } from "@/data/dtos/order.dto";
import { apiClient } from "./base.service";

const orderService = {
  create: async (payload: OrderData) =>
    await apiClient.post("/orders", payload),
  getAll: async () => await apiClient.get("/orders"),
  get: async (id: string) => await apiClient.get("/orders/" + id),
  update: async (id: string, payload: OrderData) =>
    await apiClient.patch("/orders/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/orders/" + id),
};

export default orderService;
