import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { url } from "@/utils";

export default function CourseCard({
  courseDetail,
  loading = false,
}: {
  courseDetail?: any;
  loading?: boolean;
}) {
  const {
    course,
    progress = 0,
    successRate,
    accessStart,
    accessEnd,
  } = courseDetail || {};

  const remainingTime = () => {
    if (!accessEnd) return "Süre dolmuş";
    const now = new Date().getTime();
    const remaining = Math.max(0, Date.parse(accessEnd) - now);

    if (remaining <= 0) return "Süre dolmuş";

    const months = Math.floor(remaining / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(
      (remaining % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );
    const hours = Math.floor(
      (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    if (months > 0) return `${months} ay ${days} gün ${hours} saat`;
    return `${days} gün ${hours} saat`;
  };

  if (loading) {
    return (
      <div className="w-full bg-white shadow-md rounded-lg hover:shadow-lg transition-transform duration-300 ease-out min-h-[22rem] flex flex-col">
        <div className="relative h-48 bg-gray-200 rounded-t-lg">
          <Skeleton height="100%" />
        </div>
        <div className="p-4 flex flex-col justify-between flex-1">
          <Skeleton count={1} height={16} width="70%" className="mb-2" />
          <Skeleton count={2} height={12} width="90%" className="mb-4" />
          <Skeleton height={20} width="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full bg-white shadow-md rounded-lg hover:shadow-lg hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-out min-h-[22rem] flex flex-col">
      <Link
        to={url("Profile.Course", { id: course?._id })}
        aria-label={`View details of ${course?.title || "Course"}`}
      >
        {/* Image Section */}
        <div
          className="h-48 w-full bg-gray-200 flex justify-between p-4 bg-cover bg-center rounded-t-lg relative"
          style={{
            backgroundImage: `url("${
              course?.images?.[0]?.url || "/placeholder.png"
            }")`,
          }}
        >
          <img
            src={course?.images?.[0]?.url || "/placeholder.png"}
            alt={course?.title || "Course Image"}
            className="hidden"
            onError={(e) => (e.currentTarget.src = "/placeholder.png")}
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-20"></div>
        </div>

        {/* Content Section */}
        <div className="p-4 flex flex-col justify-between flex-1">
          {/* Instructors */}
          <p
            className="text-gray-500 font-light text-xs text-center truncate"
            title={
              course?.instructors?.map((inst) => inst.name).join(", ") || ""
            }
          >
            {course.instructors?.length
              ? course.instructors.map((instructor, index) => (
                  <span className="mr-2" key={index}>
                    {instructor?.name}
                  </span>
                ))
              : "Eğitmen bilgisi yok"}
          </p>

          {/* Title */}
          <h1
            className="text-gray-800 text-left mt-2 text-lg font-semibold line-clamp-1"
            title={course?.title || "Kurs başlığı yok"}
          >
            {course?.title || "No Title"}
          </h1>

          {/* Progress Bar */}
          <div className="flex items-center w-full gap-4 mt-4">
            <p className="text-sm text-gray-700 whitespace-nowrap">
              İlerleme Düzeyi
            </p>
            <div className="w-full bg-gray-200 rounded-full h-3 relative">
              <div
                className="bg-blue-600 h-3 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              ></div>
              <p className="absolute inset-0 text-center text-xs text-white flex items-center justify-center">
                {progress}%
              </p>
            </div>
          </div>

          {/* Remaining Time */}
          <div className="flex justify-between items-center w-full mt-4 text-sm text-gray-600">
            <span>Kalan Süre:</span>
            <span className="font-semibold text-gray-800">
              {remainingTime()}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}
