import React, { useState } from "react";
import routes from "../../Routes";
import NavItem from "./NavItem";

export default function NavList() {
  const [openMenu, setOpenMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMouseEnter = (path) => {
    setOpenMenu(path);
  };

  const handleMouseLeave = () => {
    setOpenMenu(null);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <div className="relative w-full justify-items-start md:justify-items-center">
      {/* Hamburger Icon */}
      <button
        className="block md:hidden p-2 border rounded text-gray-700 focus:outline-none"
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? "✖" : "☰"}
      </button>

      {/* Navigation List */}
      <ul
        className={`absolute top-full left-0 w-full bg-white transform transition-transform duration-300 ease-in-out md:transform-none md:static md:flex md:items-center md:w-auto md:space-x-6 ${
          isMobileMenuOpen ? "block p-2" : "hidden"
        }`}
      >
        {routes.map((route) =>
          route.name === "Home"
            ? route?.children?.map((child) => (
                <li
                  key={child.path}
                  className="relative group h-20 flex items-center hover:bg-gray-50 transition-colors duration-200"
                  onMouseEnter={() => handleMouseEnter(child.path)}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavItem path={child.path} name={child.title!} />
                  {child.children && openMenu === child.path && (
                    <ul className="absolute left-0 top-3/4 mt-4 w-full bg-white border border-gray-300 shadow-lg rounded-lg z-50 md:w-72 transition-all duration-300 ease-in-out">
                      {child.children.map((subChild) => (
                        <li
                          key={subChild.path}
                          className="px-4 py-2 hover:bg-gray-100 block w-full transition-colors duration-200"
                        >
                          <NavItem
                            path={child.path + "/" + subChild.path}
                            name={subChild.title!}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))
            : null
        )}
      </ul>
    </div>
  );
}
