import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../actions/cart.actions";
import { Link, useNavigate } from "react-router-dom";
import { formatMoney, url } from "../../../utils";
import { setError } from "../../../actions/message";
import { UserContext } from "@/context/UserContext";
import { AddressDto } from "@/data/dtos/address.dto";
import { CourseDto } from "@/data/dtos/course.dto";

export default function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, total, tax, paidTotal, discount } = useSelector(
    (state: any) => state.cart
  );
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  useEffect(() => {
    if (user?.addresses) {
      console.log("user", user);
      if (user?.addresses) {
        console.log((user?.addresses[0] as AddressDto)?._id);
      } else {
        console.log(user?.addresses);
        navigate(url("Profile"));
        dispatch(
          setError({
            message: "Please add an address",
            errors: {},
          })
        );
      }
    }
  }, [user, navigate, dispatch]);

  const handleRemoveFromCart = (id: any) => {
    dispatch(removeFromCart(id) as any);
  };

  return (
    <section className="bg-white py-8 antialiased md:py-16">
      <h1 className="hidden">Sepet</h1>
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <ol className="items-center flex w-full max-w-2xl text-center text-sm font-medium text-gray-500 dark:text-gray-400 sm:text-base">
          <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200 dark:text-primary-500 dark:after:border-gray-700 sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
            <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/'] dark:after:text-gray-500 sm:after:hidden">
              <svg
                className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
              Sepetim
            </span>
          </li>
          <li className="after:border-1 flex items-center  after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  dark:after:border-gray-700 sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
            <Link to={url("Cart.Checkout")}>
              <span className="flex items-center after:mx-2 after:text-gray-200 after:content-['/'] dark:after:text-gray-500 sm:after:hidden w-full">
                <svg
                  className="me-2 h-4 w-4 sm:h-5 sm:w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  ></path>
                </svg>
                <p className=" w-max">Ödeme Yap</p>
              </span>
            </Link>
          </li>
          <li className="flex shrink-0 items-center">
            <svg
              className="me-2 h-4 w-4 sm:h-5 sm:w-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              ></path>
            </svg>
            Sipariş özeti
          </li>
        </ol>
        <h2 className="text-xl font-semibold text-gray-900  sm:text-2xl mt-4">
          Sepetim
        </h2>
        <span className="text-sm text-gray-500">
          Dikkat! Satın alınan atölyelerin katılım süresi abone olmayan
          kullanıcılar için 3 aydır!
        </span>

        <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
          <div className="mx-auto w-full flex flex-col lg:max-w-2xl xl:max-w-4xl gap-2">
            {items?.length > 0 ? (
              items?.map((item: CourseDto) => (
                <div key={item._id} className="space-y-6">
                  <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6">
                    <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                      <Link
                        to={url("Courses.Course-By-Slug", {
                          slug: item.slug,
                        })}
                        className="shrink-0 md:order-1"
                      >
                        <img
                          className="max-h-32 rounded"
                          src={
                            item.images![0]?.url || "https://fakeimg.pl/600x400"
                          }
                          alt={item.title}
                          onError={(e) => {
                            (e.target as HTMLImageElement).src =
                              "https://fakeimg.pl/600x400";
                          }}
                        />
                      </Link>

                      <label htmlFor="counter-input" className="sr-only">
                        Adet Seç:
                      </label>
                      <div className="flex items-center justify-between md:order-3 md:justify-end">
                        <div className="text-end md:order-4 md:w-32">
                          <p className="text-base font-bold text-gray-900 ">
                            {formatMoney(item.price)}
                          </p>
                        </div>
                      </div>

                      <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                        <Link
                          to={url("Courses.Course-By-Slug", {
                            slug: item.slug,
                          })}
                          className="text-base font-medium text-gray-900 hover:underline "
                        >
                          <p>{item.title}</p>
                          <span className="text-gray-400 truncate block">
                            {item.description}
                          </span>
                        </Link>

                        <div className="flex items-center gap-4">
                          {/* Add To Favorites */}
                          <button
                            type="button"
                            className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 hover:underline  "
                          >
                            <svg
                              className="me-1.5 h-5 w-5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
                              />
                            </svg>
                            Favorilere Ekle
                          </button>
                          {/* Remove From Cart */}
                          <button
                            type="button"
                            className="inline-flex items-center text-sm font-medium text-red-600 hover:underline"
                            onClick={() => handleRemoveFromCart(item._id)}
                          >
                            <svg
                              className="me-1.5 h-5 w-5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18 17.94 6M18 18 6.06 6"
                              />
                            </svg>
                            Kaldır
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="">
                <div className="rounded-lg border border-gray-200 bg-white p-2 shadow-sm">
                  <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                    <h3 className="text-center text-2xl font-semibold text-gray-900 flex gap-3">
                      Sepetiniz boş
                      <Link
                        className=" hover:text-primary-500 text-primary-700"
                        to={url("Courses")}
                      >
                        Hemen öğrenmeye Başlayın
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Summary */}

          <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm   sm:p-6">
              <p className="text-xl font-semibold text-gray-900 ">
                Sipariş Özeti
              </p>

              <div className="space-y-4">
                <div className="space-y-2">
                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">
                      Fiyat
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(total)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">
                      İndirim
                    </dt>
                    <dd className="text-base font-medium text-green-600">
                      {formatMoney(discount)}
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 ">
                      Vergi
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      {formatMoney(tax)}
                    </dd>
                  </dl>
                </div>

                <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                  <dt className="text-base font-bold text-gray-900 ">
                    Toplam Fiyat
                  </dt>
                  <dd className="text-base font-bold text-gray-900 ">
                    {formatMoney(paidTotal)}
                  </dd>
                </dl>
              </div>

              <Link
                to={url("Cart.Checkout")}
                className={`flex w-full items-center justify-center rounded-lg ${
                  items?.length > 0
                    ? "bg-primary-700 hover:bg-primary-800 text-white"
                    : "bg-gray-300 text-gray-400 cursor-not-allowed"
                } px-5 py-2.5 text-sm font-medium`}
                aria-disabled={items?.length === 0}
              >
                Ödemeye Geç
              </Link>

              <div className="flex items-center justify-center gap-2">
                <span className="text-sm font-normal text-gray-500 ">
                  {" "}
                  veya{" "}
                </span>
                <Link
                  to={url("Courses")}
                  title=""
                  className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline"
                >
                  Alışverişe Devam Et
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </Link>
              </div>
            </div>

            {/* <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm   sm:p-6">
              <form className="space-y-4">
                <div>
                  <label
                    htmlFor="voucher"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Promosyon Kodu
                  </label>
                  <input
                    type="text"
                    id="voucher"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 "
                    placeholder=""
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 "
                >
                  Kodu Uygula
                </button>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
