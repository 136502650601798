import { ChangePasswordDto } from "@/data/dtos/change-password.dto";
import { UserData } from "@/data/dtos/user.dto";
import axios from "axios";
import { apiClient } from "./base.service";

const userService = {
  getPublicContent: async () => await apiClient.get("/user"),
  create: async (payload: UserData) => await apiClient.post("/user", payload),
  getAll: async () => await apiClient.get("/user"),
  get: async (id: string) => await apiClient.get("/user/" + id),
  update: async (id: string, payload: UserData) =>
    await apiClient.patch("/user/" + id, payload),
  remove: async (id: string) => await apiClient.delete("/user/" + id),
  changePassword: async (id: string, payload: ChangePasswordDto) =>
    await apiClient.patch("/user/change-password/" + id, payload),
  getTeachers: async () => await apiClient.get("/user/teachers"),
};

export default userService;
