import {
  CREATE_AGREEMENT_REQUEST,
  GET_AGREEMENTS_REQUEST,
  GET_AGREEMENT_REQUEST,
  UPDATE_AGREEMENT_REQUEST,
  DELETE_AGREEMENT_REQUEST,
  CREATE_AGREEMENT_SUCCESS,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
  GET_AGREEMENTS_FAILURE,
  GET_AGREEMENT_FAILURE,
  UPDATE_AGREEMENT_FAILURE,
  DELETE_AGREEMENT_FAILURE,
} from "@/actions/agreements.action";
import { logger } from "@/lib/default-logger";
import { Logger } from "@/lib/logger";

interface AgreementsState {
  agreements: any[];
  agreement: any;
  loading: boolean;
  error: string | null;
}

const initialState: AgreementsState = {
  agreements: [],
  agreement: null,
  loading: false,
  error: null,
};

const agreementsReducer = (
  state = initialState,
  action: any
): AgreementsState => {
  switch (action.type) {
    case CREATE_AGREEMENT_REQUEST:
    case GET_AGREEMENTS_REQUEST:
    case GET_AGREEMENT_REQUEST:
    case UPDATE_AGREEMENT_REQUEST:
    case DELETE_AGREEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreements: [...state.agreements, action.payload.data],
      };
    case GET_AGREEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agreements: action.payload.data,
      };
    case GET_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreement: action.payload.data,
      };

    case UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreements: state.agreements.map((agreement) => {
          if (agreement._id === action.payload.data._id) {
            return { ...agreement, ...action.payload.data };
          }
          return agreement;
        }),
      };

    case DELETE_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreements: state.agreements.filter(
          (agreement) => agreement._id !== action.payload
        ),
      };

    case CREATE_AGREEMENT_FAILURE:
    case GET_AGREEMENTS_FAILURE:
    case GET_AGREEMENT_FAILURE:
    case UPDATE_AGREEMENT_FAILURE:
    case DELETE_AGREEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default agreementsReducer;
