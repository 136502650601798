import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";

export default function FictionalEvent() {
  return (
    <div className="mx-auto max-w-4xl justify-items-center">
      <section className="py-24 relative">
        <div id="about" className="relative overflow-hidden flex gap-10">
          <div className="mx-auto ">
            <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
              <div className="text text-justify">
                <h1 className="tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl mb-2 hidden ">
                  Kurgusal Etkinlik Platformumuz
                  <p className="text-orange-50">Hakkında</p>
                </h1>

                <div>
                  <p>
                    <h2 className="font-bold text-gray-900">
                      Kurgusal Etkinlik İle Ne Demek İstiyoruz?
                    </h2>
                    <br />
                    Kurumların ihtiyacı olan etkinlik ve organizasyonlarda ilham
                    veren konuşmaları kurumunuzun ihtiyacına yönelik
                    özelleştirerek sunmaktan söz ediyoruz. Özetle standart
                    içerikteki eğitim paketleri ile seslendiğimiz online eğitim
                    platformumuzdan farklı olarak sizleri burada özelleştirilmiş
                    içerik ile buluşturmayı vaat ediyoruz ki yeni nesil iş
                    dünyası için bu noktada tercih edilir olacağımız
                    kanaatindeyiz. Kurgusal Etkinlik Başlığı Altında Verdiğimiz
                    Hizmetlerin Açılımı Bize vereceğiniz hedef brief çalışması
                    ardından sizler için yapmayı planladığınız etkinliğe uygun;
                    *İlham veren konuşmayı kim yapabilir? *İlham veren konuşmacı
                    bu konuşmayı hangi içerikte yapabilir? *Bu etkinliği kim
                    sunabilir/moderatörlüğünü kim yapabilir? *Etkinlikte kurumu
                    temsil eden kurum yetkilisinin sunumu nasıl olmalı ve nasıl
                    sunulmalı? Sunum ve Akış hazırlama hizmetleri… *Etkinlik
                    adına PR çalışması/Etkinliğin medyaya yansımasının
                    planlanması *Etkinliğin hedefinin eş zamanlı olarak sosyal
                    medya projelerine, yotube projelerine ve kanaat önderi iş
                    insanları ile gerçekleştirilecek influencer çalışmalarına
                    dönüştürülmesi ve uygulanması süreçlerinin kurgu ve yönetimi
                    hizmet açılımımızı tanımlamaktadır.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 bg-[#fce49f] h-fit rounded shadow p-8">
            <h2 className="text-xl font-bold ">Bizimle İletişime Geçin</h2>
            <div className="w-full rounded p-4">
              <div className="flex flex-col gap-4 justify-center">
                <div className="flex">
                  <FaMapMarkerAlt className="text-indigo-500 text-2xl mr-4" />
                  <span>
                    Fethiye Mh. Sanayi Cd.CornerPlus İş Merkezi No:263/a
                    Nilüfer/BURSA
                  </span>
                </div>
                <div className="flex">
                  <FaPhone className="text-indigo-500 text-2xl mr-4" />
                  <span>+90 (224) 234 02 37 - (538) 605 83 30</span>
                </div>
                <div className="flex">
                  <FaEnvelope className="text-indigo-500 text-2xl mr-4" />
                  <span>info@saymedya.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
