import React, { useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../actions/category.actions";

enum LanguageEnum {
  TURKISH = "Türkçe",
  ENGLISH = "English",
  SPANISH = "Spanish",
  FRENCH = "French",
  GERMAN = "German",
  ITALIAN = "Italian",
  JAPANESE = "Japanese",
  KOREAN = "Korean",
  PORTUGUESE = "Portuguese",
  RUSSIAN = "Russian",
  CHINESE = "Chinese",
  ARABIC = "Arabic",
  INDONESIAN = "Indonesian",
  HINDI = "Hindi",
  VIETNAMESE = "Vietnamese",
  MALAY = "Malay",
  THAI = "Thai",
  TELUGU = "Telugu",
  PERSIAN = "Persian",
  HEBREW = "Hebrew",
  CATALAN = "Catalan",
  POLISH = "Polish",
  BULGARIAN = "Bulgarian",
  CROATIAN = "Croatian",
  CZECH = "Czech",
  DANISH = "Danish",
  DUTCH = "Dutch",
  ESTONIAN = "Estonian",
  FINNISH = "Finnish",
  GREEK = "Greek",
  HUNGARIAN = "Hungarian",
  ICELANDIC = "Icelandic",
  IRISH = "Irish",
  LITHUANIAN = "Lithuanian",
  LATVIAN = "Latvian",
  LUXEMBOURGISH = "Luxembourgish",
  MALTESE = "Maltese",
  NORWEGIAN = "Norwegian",
  ROMANIAN = "Romanian",
  SERBIAN = "Serbian",
  SLOVAK = "Slovak",
  SWEDISH = "Swedish",
  SWISS = "Swiss",
  UKRAINIAN = "Ukrainian",
  UZBEK = "Uzbek",
  ZULU = "Zulu",
  SLOVENIAN = "Slovenian",
  OTHER = "Other",
}

enum StatusEnum {
  DRAFT = "Draft",
  PUBLISHED = "Published",
  REJECTED = "Rejected",
  ARCHIVED = "Archived",
}

enum LevelEnum {
  BEGGINER = "Begginer",
  INTERMEDIATE = "Intermediate",
  ADVANCED = "Advanced",
}

const languageArray = [
  LanguageEnum.TURKISH,
  LanguageEnum.ENGLISH,
  LanguageEnum.FRENCH,
  LanguageEnum.GERMAN,
  LanguageEnum.ITALIAN,
  LanguageEnum.JAPANESE,
  LanguageEnum.KOREAN,
  LanguageEnum.PORTUGUESE,
  LanguageEnum.RUSSIAN,
  LanguageEnum.CHINESE,
  LanguageEnum.ARABIC,
  LanguageEnum.INDONESIAN,
  LanguageEnum.HINDI,
  LanguageEnum.VIETNAMESE,
  LanguageEnum.MALAY,
  LanguageEnum.THAI,
  LanguageEnum.TELUGU,
  LanguageEnum.PERSIAN,
  LanguageEnum.HEBREW,
  LanguageEnum.CATALAN,
  LanguageEnum.POLISH,
  LanguageEnum.BULGARIAN,
  LanguageEnum.CROATIAN,
  LanguageEnum.CZECH,
  LanguageEnum.DANISH,
  LanguageEnum.DUTCH,
  LanguageEnum.FINNISH,
  LanguageEnum.GREEK,
  LanguageEnum.LATVIAN,
  LanguageEnum.LITHUANIAN,
  LanguageEnum.NORWEGIAN,
  LanguageEnum.ROMANIAN,
  LanguageEnum.SERBIAN,
  LanguageEnum.SLOVAK,
  LanguageEnum.SLOVENIAN,
  LanguageEnum.SWEDISH,
  LanguageEnum.UKRAINIAN,
  LanguageEnum.OTHER,
];

interface CourseInfoProps {
  courseData: any;
  onChange: (field: string, value: any) => void;
}

const CourseInfo: React.FC<CourseInfoProps> = ({ courseData, onChange }) => {
  const dispatch = useDispatch();
  const { categories, status } = useSelector((state: any) => state.categories);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [dispatch, status]);

  return (
    <Box>
      <Typography variant="h6">Atölye Bilgileri</Typography>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center">
        <TextField
          label="Atölye Başlığı"
          fullWidth
          margin="normal"
          value={courseData.title}
          onChange={(e) => onChange("title", e.target.value)}
          className="col-span-2"
        />
        <TextField
          label="Atölye Açıklaması"
          fullWidth
          margin="normal"
          multiline
          value={courseData.description}
          onChange={(e) => onChange("description", e.target.value)}
          className="col-span-2"
        />
        <div>
          <InputLabel id="level-label">Seviye</InputLabel>
          <Select
            labelId="level-label"
            id="level-select"
            fullWidth
            value={courseData.level}
            label="Seviye"
            onChange={(e) => onChange("level", e.target.value)}
            className="col-span-1"
          >
            <MenuItem value={LevelEnum.BEGGINER}>Başlangıç</MenuItem>
            <MenuItem value={LevelEnum.INTERMEDIATE}>Orta Seviye</MenuItem>
            <MenuItem value={LevelEnum.ADVANCED}>İleri Düzey</MenuItem>
          </Select>
        </div>
        <div className="col-span-1">
          <InputLabel id="language-label">Diller</InputLabel>
          <Select
            labelId="language-label"
            id="language-select"
            fullWidth
            value={courseData.languages}
            label="Diller"
            onChange={(e) => onChange("languages", e.target.value)}
            className="col-span-1"
            multiple
          >
            {languageArray.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <InputLabel id="status-label">Durum</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            fullWidth
            value={courseData.status}
            label="Durum"
            onChange={(e) => onChange("status", e.target.value)}
            className="col-span-1"
          >
            <MenuItem value={StatusEnum.DRAFT}>Taslak</MenuItem>
            <MenuItem value={StatusEnum.PUBLISHED}>Yayınlandı</MenuItem>
            <MenuItem value={StatusEnum.ARCHIVED}>Arşiv</MenuItem>
          </Select>
        </div>

        <div>
          <InputLabel id="categories-label">Kategoriler</InputLabel>
          <Select
            labelId="categories-label"
            id="categories-select"
            fullWidth
            value={courseData.categories}
            label="Kategori"
            multiple
            onChange={(e) => onChange("categories", e.target.value)}
            className="col-span-1"
            required
          >
            <MenuItem value={[]} selected disabled>
              Lütfen Kategori Seçin
            </MenuItem>
            {categories.map((category: any) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </Box>
  );
};

export default CourseInfo;
