import React, { useEffect, useState, useRef } from "react";
import { io, Socket } from "socket.io-client";
// import Room from "./liveClassComponents/Room";
import { useSelector } from "react-redux";
import Peer from "simple-peer";
import Room from "./Room";
import Chat from "./Chat";

const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2,
};

export default function LiveClassComponent({ resource }) {
  const { user } = useSelector((state: any) => state.auth);
  const [users, setUsers] = useState<any[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [peers, setPeers] = useState<any>([]);
  const socketRef = useRef<any>();
  const userVideo = useRef<HTMLVideoElement>(null);
  const peersRef = useRef<any>([]);

  useEffect(() => {
    socketRef.current = io(
      process.env.REACT_APP_SERVER_URL +
        ":" +
        process.env.REACT_APP_SOCKET_PORT +
        "/chat",
      {
        autoConnect: true,
        transports: ["websocket", "polling"],
        withCredentials: true,
      }
    );
    setSocket(socketRef.current);

    // Odaya yalnızca bir kez katılmayı sağlamak için kontrol
    socketRef.current.on("connect", () => {
      console.log("userJoined", socketRef.current.id);
      if (resource._id) {
        socketRef.current.emit("joinRoom", {
          roomName: resource._id,
          socketId: socketRef.current.id,
          user,
        });
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("userLeft", socketRef.current.id);
      socketRef.current.emit("leaveRoom", {
        roomName: resource._id,
        socketId: socketRef.current.id,
        user,
      });
    });

    socketRef.current.on("close", () => {
      console.log(socketRef.current.id);
      console.log("Bağlantı kesildi");
    });

    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
        socketRef.current.emit("joinRoom", {
          roomName: resource._id,
          socketId: socketRef.current.id,
          user,
        });
        socketRef.current.on("allUsers", (users) => {
          console.log("allUsers", users);
          const peers: any = [];
          users.forEach((userID) => {
            console.log("peers", userID);
            const peer = createPeer(userID, socketRef.current.id, stream);
            peersRef.current.push({
              peerID: userID,
              peer,
            });
            peers.push(peer);
          });
          setPeers(peers);
        });

        socketRef.current.on("userJoined", (payload) => {
          console.log("userJoined", payload);
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });

          // setPeers((users) => [...users, peer]);
        });

        socketRef.current.on("receivingReturnedSignal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          console.log("receivingReturnedSignal", item.peer);
          if (item && item.peer && !item.peer.destroyed) {
            // Ek bağlantı kontrolü
            console.log("receivingReturnedSignal", item.peer);
            item.peer.signal(payload.signal);
          } else {
            console.warn("Cannot signal, peer is destroyed or does not exist.");
          }
        });
      });

    socketRef.current.on("userLeft", (leavingUser) => {
      console.log("userLeft", leavingUser);
      if (leavingUser) {
        setUsers((prevUsers) =>
          prevUsers.filter((u) => u.id !== leavingUser?.id)
        );
      }
    });

    socketRef.current.on("allUsers", (allUsers) => {
      setUsers(
        allUsers.filter((currentUser) => {
          console.log(
            "current user:",
            currentUser,
            "user:",
            socketRef.current.id
          );
          return currentUser !== socketRef.current.id;
        })
      );
    });

    return () => {
      socketRef.current.disconnect();
      if (socketRef.current.readyState === 1) {
        socketRef.current?.close();
      }
    };
  }, [resource._id, user]);

  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      socketRef.current.emit("sendingSignal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", (signal) => {
      console.log("returningSignal to ", callerID);
      socketRef.current.emit("returningSignal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  return (
    <div className="md:flex grid grid-cols-1 gap-4 w-full">
      <div className="md:w-3/4 w-full">
        <Room resource={resource} />
      </div>
      {socket && (
        <div className="md:w-1/4 w-full">
          <Chat
            user={user}
            currentCourse={resource}
            socket={socket}
            users={users}
          />
        </div>
      )}
    </div>
  );
}
