import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { formatMoney } from "@/utils";

interface CourseData {
  id: number;
  name: string;
  sales: number;
  revenue: number;
  tax: number;
  discount: number;
}

export default function CoursePerformanceTable({
  groupedData,
}: {
  groupedData: CourseData[];
}) {
  // Sütun tanımlamaları
  const columns: GridColDef[] = [
    { field: "name", headerName: "Atölye Adı", flex: 1 },
    { field: "sales", headerName: "Satış Adedi", flex: 0.5, type: "number" },
    {
      field: "revenue",
      headerName: "Satış Fiyatı",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "tax",
      headerName: "Toplam Vergi",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "discount",
      headerName: "Toplam İndirim",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
  ];

  // `id` zorunlu bir alan olduğu için `groupedData`'ya `id` ekliyoruz
  const rows = groupedData.map((item, index) => ({
    id: index, // Her satıra benzersiz bir ID
    name: item.name,
    sales: item.sales,
    revenue: item.revenue,
    tax: item.tax,
    discount: item.discount,
  }));

  const pageSizeOptions = [25, 50, 100];

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Tüm Atölye Performansı</h2>
      <div style={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
        />
      </div>
    </div>
  );
}
