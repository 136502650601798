import React, { forwardRef, useEffect, useRef } from "react";
interface TextInputProps {
  type?: string;
  className?: string;
  isFocused?: boolean;
  [key: string]: any;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ type = "text", className = "", isFocused = false, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const input = ref || inputRef;

    useEffect(() => {
      if (isFocused) {
        inputRef.current?.focus();
      }
    }, [isFocused]);

    return (
      <input
        {...props}
        type={type}
        className={`border-gray-300 p-1   focus:border-indigo-500  focus:ring-indigo-500 rounded-md shadow-sm ${className}`}
        ref={input}
      />
    );
  }
);

export default TextInput;
