import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { CheckBox } from "@mui/icons-material";
import { ResourceDto } from "@/data/dtos/resource.dto";

export default function CourseSection({
  sections,
  setCurrentResource,
  setCurrentSection,
  courseProgress,
}) {
  const [selectedResource, setSelectedResource] = useState<ResourceDto | null>(
    null
  );
  return (
    <div className="md:w-1/5 w-full overflow-y-scroll pe-4">
      {sections
        ?.sort((a, b) => a.order - b.order)
        .map((section, index) => (
          <Accordion key={section._id ?? index} className="border rounded">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ flexGrow: 1 }}>{section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col gap-2">
                {section.resources
                  ?.sort((a, b) => a.order - b.order)
                  .map((resource) => (
                    <button
                      key={resource._id}
                      className="flex w-full"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedResource?._id === resource._id
                            ? "#f1f1f1"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedResource(resource);
                        setCurrentResource(resource);
                        setCurrentSection(section);
                      }}
                    >
                      <div className="flex gap-4 p-4">
                        <input
                          type="checkbox"
                          name="isComplated"
                          checked={courseProgress?.some((progress) =>
                            progress.resources?.some(
                              (rsc) =>
                                rsc.resourceId === resource._id &&
                                rsc.isCompleted
                            )
                          )}
                        />
                        <span className="w-full text-left">
                          <Typography>{resource.title}</Typography>
                        </span>
                      </div>
                    </button>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
