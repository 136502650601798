// src/pages/Forbidden.tsx
import { useLocationContext } from "@/context/LocationContext";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Forbidden = () => {
  const navigate = useNavigate();
  const { previousPath } = useLocationContext();
  console.log(previousPath);

  // Kullanıcıyı geri gönderen fonksiyon
  const redirectBack = () => {
    const from = previousPath || "/";
    navigate(from, { replace: true });
  };

  // Belirli bir süre sonra yönlendirme yapmak (opsiyonel)
  useEffect(() => {
    const timer = setTimeout(redirectBack, 5000); // 5 saniye sonra geri gönder
    return () => clearTimeout(timer); // Bileşen unmount olduğunda temizle
  });

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800">
      <h1 className="text-6xl font-bold text-red-600">403</h1>
      <h2 className="text-2xl font-semibold mt-4">Yetkisiz Erişim</h2>
      <p className="text-gray-600 mt-2 text-center">
        Bu sayfaya erişim izniniz yok. Lütfen gerekli izinlere sahip
        olduğunuzdan emin olun.
      </p>
      <Link
        to="/"
        className="mt-6 bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition"
      >
        Ana Sayfaya Dön
      </Link>
    </div>
  );
};

export default Forbidden;
