import { apiClient } from "./base.service";

const sectionsService = {
  create: async (payload: any) => await apiClient.post("/sections", payload),
  getAll: async () => await apiClient.get("/sections"),
  get: async (id: string) => await apiClient.get("/sections/" + id),
  remove: async (id: string) => await apiClient.delete("/sections/" + id),
  update: async (id: string, payload: any) =>
    await apiClient.patch("/sections/" + id, payload),
  getSectionsByCourse: async (id: string) =>
    await apiClient.get("/sections/course/" + id),
};

export default sectionsService;
