import { getAll } from "@/actions/setting.action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentComponent from "./ContentComponent";

export default function AboutOnlineEducation() {
  const dispatch = useDispatch();
  const { settings, status } = useSelector((state: any) => state.settings);
  const [about, setAbout] = useState<any>(null);
  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    const about = settings.find(
      (setting: any) => setting.key === "aboutOnlineEducation"
    );
    if (settings) {
      console.log(about?.value);
      setAbout(about?.value);
    }
  }, [settings]);
  return (
    <div className="w-full justify-items-center">
      <ContentComponent about={about} />
    </div>
  );
}
