import { AgreementsDto } from "@/data/dtos/agreements.dto";
import agreementsService from "@/services/agreements.service";
import { Dispatch } from "redux";
import { setError, setMessage } from "./message";
import { UserAgreementsData } from "@/data/dtos/user-agreements.dto";

export const CREATE_AGREEMENT_REQUEST = "CREATE_AGREEMENT_REQUEST";
export const CREATE_AGREEMENT_SUCCESS = "CREATE_AGREEMENT_SUCCESS";
export const CREATE_AGREEMENT_FAILURE = "CREATE_AGREEMENT_FAILURE";

export const GET_AGREEMENTS_REQUEST = "GET_AGREEMENTS_REQUEST";
export const GET_AGREEMENTS_SUCCESS = "GET_AGREEMENTS_SUCCESS";
export const GET_AGREEMENTS_FAILURE = "GET_AGREEMENTS_FAILURE";

export const GET_AGREEMENT_REQUEST = "GET_AGREEMENT_REQUEST";
export const GET_AGREEMENT_SUCCESS = "GET_AGREEMENT_SUCCESS";
export const GET_AGREEMENT_FAILURE = "GET_AGREEMENT_FAILURE";

export const UPDATE_AGREEMENT_REQUEST = "UPDATE_AGREEMENT_REQUEST";
export const UPDATE_AGREEMENT_SUCCESS = "UPDATE_AGREEMENT_SUCCESS";
export const UPDATE_AGREEMENT_FAILURE = "UPDATE_AGREEMENT_FAILURE";

export const DELETE_AGREEMENT_REQUEST = "DELETE_AGREEMENT_REQUEST";
export const DELETE_AGREEMENT_SUCCESS = "DELETE_AGREEMENT_SUCCESS";
export const DELETE_AGREEMENT_FAILURE = "DELETE_AGREEMENT_FAILURE";

export const CREATE_USER_AGREEMENT_REQUEST = "CREATE_USER_AGREEMENT_REQUEST";
export const CREATE_USER_AGREEMENT_SUCCESS = "CREATE_USER_AGREEMENT_SUCCESS";
export const CREATE_USER_AGREEMENT_FAILURE = "CREATE_USER_AGREEMENT_FAILURE";

export const SET_MESSAGE = "SET_MESSAGE";
export const SET_ERROR = "SET_ERROR";

export const create =
  (payload: AgreementsDto) => async (dispatch: Dispatch) => {
    dispatch({ type: CREATE_AGREEMENT_REQUEST });
    try {
      const response = await agreementsService.create(payload);
      if (response && response.data) {
        dispatch({ type: CREATE_AGREEMENT_SUCCESS, payload: response.data });
        dispatch(setMessage("Sözleşme Oluşturuldu", 5000) as any);
      } else {
        dispatch({ type: CREATE_AGREEMENT_FAILURE, error: "No response data" });
        dispatch(setError("Sözleşme oluşturulurken hata oluştu.") as any);
      }
    } catch (error: any) {
      dispatch({ type: CREATE_AGREEMENT_FAILURE, error });
      dispatch(setError(error.message) as any);
    }
  };

export const getAll = () => async (dispatch: Dispatch) => {
  dispatch({ type: GET_AGREEMENTS_REQUEST });
  try {
    const response = await agreementsService.getAll();
    if (response && response.data) {
      dispatch({ type: GET_AGREEMENTS_SUCCESS, payload: response.data });
    } else {
      dispatch({ type: GET_AGREEMENTS_FAILURE, error: "No response data" });
      dispatch(setError("Sözleşmeler getirilirken sorun oluştu") as any);
    }
  } catch (error: any) {
    dispatch({ type: GET_AGREEMENTS_FAILURE, error });
    dispatch(setError(error.message) as any);
  }
};

export const get = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: GET_AGREEMENT_REQUEST });
  try {
    const response = await agreementsService.getById(id);
    if (response && response.data) {
      dispatch({ type: GET_AGREEMENT_SUCCESS, payload: response.data });
    } else {
      dispatch({ type: GET_AGREEMENT_FAILURE, error: "No response data" });
      dispatch(setError("Sözleşme getirilirken sorun oluştu") as any);
    }
  } catch (error: any) {
    dispatch({ type: GET_AGREEMENT_FAILURE, error });
    dispatch(setError(error.message) as any);
  }
};

export const update =
  (id: string, payload: AgreementsDto) => async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_AGREEMENT_REQUEST });
    try {
      const response = await agreementsService.update(id, payload);
      if (response && response.data) {
        dispatch({ type: UPDATE_AGREEMENT_SUCCESS, payload: response.data });
        dispatch(setMessage("Sözleşme başarıyla düzenlendi", 5000) as any);
      } else {
        dispatch({ type: UPDATE_AGREEMENT_FAILURE, error: "No response data" });
        // dispatch(setError("Sözleşme güncellenirken hata oluştu.") as any);
      }
    } catch (error: any) {
      dispatch({ type: UPDATE_AGREEMENT_FAILURE, error });
      dispatch(setError(error.message) as any);
    }
  };

export const remove = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: DELETE_AGREEMENT_REQUEST });
  try {
    await agreementsService.delete(id);
    dispatch({ type: DELETE_AGREEMENT_SUCCESS, payload: id });
    dispatch(setMessage("Sözleşme başarıyla silindi", 5000) as any);
  } catch (error: any) {
    dispatch({ type: DELETE_AGREEMENT_FAILURE, error });
    dispatch(setError(error.message) as any);
  }
};

export const createUserAgreement =
  (payload: UserAgreementsData) => async (dispatch: Dispatch) => {
    dispatch({ type: CREATE_USER_AGREEMENT_REQUEST });
    try {
      await agreementsService.createUserAgreement(payload);
      dispatch({ type: CREATE_USER_AGREEMENT_SUCCESS });
      // dispatch(setMessage("Sözleşme başarıyla oluşturuldu", 5000) as any);
    } catch (error: any) {
      dispatch({ type: CREATE_USER_AGREEMENT_FAILURE, error });
      dispatch(setError(error.message) as any);
    }
  };
