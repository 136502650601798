interface SectionState {
  sections: any[];
  currentSection: any;
  status: string;
}

const initialState: SectionState = {
  sections: [],
  currentSection: null,
  status: "",
};

export default function sectionsReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "CREATE_SECTION_SUCCESS":
      return {
        ...state,
        sections: [...state.sections, payload],
        status: "success",
      };

    case "FETCH_SECTIONS_SUCCESS":
      return {
        ...state,
        sections: payload,
        status: "success",
      };

    case "FETCH_SECTION_SUCCESS":
      return {
        ...state,
        currentSection: payload,
        status: "success",
      };

    case "UPDATE_SECTION_SUCCESS":
      return {
        ...state,
        sections: state.sections.map((section: any) =>
          section._id === payload._id ? payload : section
        ),
        status: "success",
      };

    case "DELETE_SECTION_SUCCESS":
      return {
        ...state,
        sections: state.sections.filter(
          (section: any) => section._id !== payload
        ),
        status: "success",
      };

    default:
      return state;
  }
}
