import { getAll } from "@/actions/setting.action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentComponent from "./ContentComponent";

const About = () => {
  const dispatch = useDispatch();
  const { settings, status } = useSelector((state: any) => state.settings);
  const [about, setAbout] = useState<any>(null);
  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    const about = settings.find((setting: any) => setting.key === "about");
    if (settings) {
      console.log(about?.value);
      setAbout(about?.value);
    }
  }, [settings]);
  return (
    <div className="w-full justify-items-center">
      <ContentComponent about={about} />

      <section className="my-8">
        <div className="w-full lg:justify-center justify-center items-center sm:gap-10 gap-5 inline-flex">
          <div className="flex-col justify-start items-start inline-flex">
            <h3 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
              30+
            </h3>
            <h6 className="text-gray-500 text-base font-normal leading-relaxed">
              Yıl Tecrübe
            </h6>
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <h4 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
              152+
            </h4>
            <h6 className="text-gray-500 text-base font-normal leading-relaxed">
              Başarılı Proje
            </h6>
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <h4 className="text-gray-900 text-4xl font-bold font-manrope leading-normal">
              500+
            </h4>
            <h6 className="text-gray-500 text-base font-normal leading-relaxed">
              Mutlu Müşteri
            </h6>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
