import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../utils";

export default function ApplicationLogo({ className }: { className: string }) {
  return (
    <Link to={url("Home")} className={`w-28 ${className}`}>
      <img src="/logo.png" alt="logo" className="w-full" />
    </Link>
  );
}
