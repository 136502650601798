import { AgreementsDto } from "@/data/dtos/agreements.dto";
import { apiClient } from "./base.service";
import { UserAgreementsData } from "@/data/dtos/user-agreements.dto";

const agreementsService = {
  create: async (payload: AgreementsDto) =>
    await apiClient.post("/agreements", payload),
  getAll: async () => await apiClient.get("/agreements"),
  getById: async (id: string) => await apiClient.get(`/agreements/${id}`),
  update: async (id: string, payload: AgreementsDto) =>
    await apiClient.patch(`/agreements/${id}`, payload),
  delete: async (id: string) => await apiClient.delete(`/agreements/${id}`),
  createUserAgreement: async (payload: UserAgreementsData) =>
    await apiClient.post("/user-agreements", payload),
};

export default agreementsService;
