import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { formatMoney } from "@/utils";
import { CourseDto } from "@/data/dtos/course.dto";

interface ItemsData {
  course: CourseDto;
  quantity: number;
  price: number;
  discount: number;
  tax: number;
  createdAt: Date;
}

export default function CourseSalesTable({ items }: { items: ItemsData[] }) {
  // Sütun tanımlamaları
  const columns: GridColDef[] = [
    {
      field: "course",
      headerName: "Atölye Adı",
      flex: 1,
      valueGetter: (params: any) => {
        console.log(params);
        return params.title;
      },
    },
    { field: "quantity", headerName: "Satış Adedi", flex: 0.5, type: "number" },
    {
      field: "price",
      headerName: "Satış Fiyatı",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "tax",
      headerName: "Toplam Vergi",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "discount",
      headerName: "Toplam İndirim",
      flex: 0.5,
      type: "number",
      valueFormatter: (params: any) => formatMoney(params),
    },
    {
      field: "createdAt",
      headerName: "Satış Tarihi",
      flex: 0.5,
      type: "date",
      valueFormatter: (params: Date) => {
        const date = new Date(params);
        return date.toLocaleDateString("tr-TR");
      },
    },
  ];

  // `id` zorunlu bir alan olduğu için `groupedData`'ya `id` ekliyoruz
  const rows = items.map((item, index) => ({
    id: index, // Her satıra benzersiz bir ID
    course: item.course,
    quantity: item.quantity,
    price: item.price,
    tax: item.tax,
    discount: item.discount,
    createdAt: item.createdAt,
  }));

  const pageSizeOptions = [25, 50, 100];

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Tüm Atölye Satışları</h2>
      <div style={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
        />
      </div>
    </div>
  );
}
