import { getAll, remove } from "@/actions/agreements.action";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { AgreementsDto } from "@/data/dtos/agreements.dto";
import { url } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AgreementList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { agreements } = useSelector((state: any) => state.agreements);

  useEffect(() => {
    dispatch(getAll() as any);
  }, [dispatch]);

  const handleDelete = async (id: string) => {
    dispatch(remove(id) as any);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      flex: 0.1,
    },
    {
      field: "title",
      headerName: "Başlık",
      flex: 0.2,
    },
    {
      field: "content",
      headerName: "İçerik",
      flex: 0.2,
    },
    {
      field: "version",
      headerName: "Versiyon",
      flex: 0.2,
    },
    {
      field: "dynamicFields",
      headerName: "Dinamik Alanlar",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div>
            {Object.keys(params.row.dynamicFields).map((key: string) => {
              return (
                <div key={key}>
                  <Typography>{key}</Typography>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Oluşturulma Tarihi",
      flex: 0.2,
      valueGetter: (param: Date) => param.toLocaleDateString("tr-TR"),
    },
    {
      field: "options",
      headerName: "Seçenekler",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        };
        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row.id}
              onEdit={() => {
                navigation(url("Admin.Agreements.Edit", { id: params.row.id }));
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen}
            />
          </Stack>
        );
      },
    },
  ];

  const rows = agreements?.map((agreement: AgreementsDto, index: number) => ({
    id: agreement._id,
    no: index + 1,
    title: agreement.title,
    content: agreement.content,
    version: agreement.version,
    dynamicFields: agreement.dynamicFields,
    createdAt: new Date(agreement.createdAt!),
  }));

  const pageSizeOptions = [25, 50, 100];

  return (
    <div className="rounded bg-white shadow p-4">
      <Typography className="my-4">Sözleşme Listesi</Typography>
      <div className="h-auto w-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
        />
      </div>
    </div>
  );
}
