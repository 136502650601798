import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "./Nav";
import Footer from "@/components/core/Footer";
import { Helmet } from "react-helmet-async";

const MainLayout = () => {
  const isChildMetaPresent = document.head.querySelector(
    '[data-react-helmet="true"]'
  );
  return (
    <div className="flex flex-col min-h-screen bg-[#81BFDA]  relative">
      {!isChildMetaPresent && (
        <Helmet>
          <title>Udemy Clone</title>
          <meta name="description" content="Udemy Clone Varsayılan Açıklama" />
        </Helmet>
      )}
      {/* Header */}
      <Nav />

      {/* Main Content */}
      <main className="flex-grow mt-24">
        <Outlet></Outlet>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MainLayout;
