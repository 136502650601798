import React, { useState } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";

export interface FictionalApplyFormProps {
  name: string;
  surname: string;
  company: string;
  emila: string;
  phone: string;
  message: string;
  date: string;
}

export default function FictionalApplyForm() {
  const [formData, setFormData] = useState<FictionalApplyFormProps>({
    name: "",
    surname: "",
    company: "",
    emila: "",
    phone: "",
    message: "",
    date: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Form Data:", formData);
    // Burada form verilerini işleyebilirsiniz
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        mx: "auto",
        mt: 0,
        p: 3,
        boxShadow: 3,
      }}
      className="bg-[#fce49f]"
    >
      <Typography variant="h5" mb={2} textAlign="center">
        Kurgusal Etkinlik Başvurusu
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Ad"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Soyad"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Şirket"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-Posta"
              name="emila"
              type="email"
              value={formData.emila}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefon"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Talep Edilen Etkinlik İçeriği"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Talep Tarihi"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Gönder
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
