import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Nav from "./Nav";
import Footer from "@/components/core/Footer";
import About from "../home/abouts/About";

const AboutLayout = () => {
  const location = useLocation();
  return (
    <div className="flex flex-col min-h-screen bg-white  relative">
      {/* Main Content */}
      <main className="flex-grow container mx-auto">
        {/* Eğer Outlet içerik sağlıyorsa, onu göster */}
        {location.pathname === "/about" && !location.state ? (
          <About />
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
};

export default AboutLayout;
