import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { LocationProvider } from "./context/LocationContext";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { UserProvider } from "./context/UserContext";
import "./i18n";
import { CourseCardProvider } from "./context/CourseCardContext ";
import { HelmetProvider } from "react-helmet-async";
import { MetaProvider } from "./context/MetaContext";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <LocationProvider>
              <AgoraRTCProvider client={client}>
                <UserProvider>
                  <CourseCardProvider>
                    <MetaProvider>
                      <HelmetProvider>
                        <App />
                      </HelmetProvider>
                    </MetaProvider>
                  </CourseCardProvider>
                </UserProvider>
              </AgoraRTCProvider>
            </LocationProvider>
          </LocalizationProvider>
        </AuthProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
