import { formatMoney, replacePlaceholders } from "@/utils";
import { Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DistanceSellingAgreement({
  isOpen,
  setIsOpen,
  items,
  paidTotal,
  shippingAddress,
  billingAddress,
  user,
  setContent,
}) {
  const { agreement } = useSelector((state: any) => state.agreements);
  const { settings } = useSelector((state: any) => state.settings);
  const [compInfo, setCompInfo] = useState<any>(null);

  const dynamicContent = replacePlaceholders(agreement?.content ?? "", {
    buyerName: user?.name ?? "Kullanıcı",
    sallerName: compInfo?.[0].name ?? "Firma Adı",
    sallerAddress: compInfo?.[0].address ?? "Firma Adresi",
    sallerPhone:
      compInfo?.[0].phones?.reduce((acc, phone) => {
        return acc + "-" + phone;
      }) ?? "Firma Telefonu",
    sallerEmail: compInfo?.[0].email ?? "Firma Email",
    sallerFax: compInfo?.[0].fax ?? "Firma Fax",
    buyerAddress: shippingAddress.address ?? "Adres bulunamadı",
    billingAddress: billingAddress.address ?? "Fatura adresi seçilmedi",
    deleverToName: shippingAddress.contactName ?? "Kişi yok",
    deleverToAddress: shippingAddress.address ?? "Adres yok",
    deleverToPhone: user?.phone ?? "Telefon yok",
    deleverToEmail: user?.email ?? "Email yok",
    orderToName: billingAddress.contactName ?? "Kişi yok",
    orderToAddress: billingAddress.address ?? "Adres yok",
    orderToPhone: user?.phone ?? "Telefon yok",
    orderToEmail: user?.email ?? "Email yok",
    shippingFee: "0",
    paidPrice: formatMoney(paidTotal),
    receiptName: billingAddress.contactName ?? "Kişi yok",
    receiptAddress: billingAddress.address ?? "Adres yok",
    receiptPhone: user?.phone ?? "Telefon yok",
    receiptMail: user?.email ?? "Email yok",
    productTable: `
        <table style="width: 100%; border-collapse: collapse; border-spacing: 0; margin-bottom: 1rem; border: 1px solid #e2e8f0; text-align: left;">
                  <thead style="background-color: #f5f5f5;">
                      <tr>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">Ürün Adı</th>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">Adet</th>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">Fiyat</th>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">İndirim</th>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">Vergi</th>
                          <th style="padding: 8px; border: 1px solid #e2e8f0;">Toplam</th>
                      </tr>
                  </thead>
                  <tbody>
                        ${items
                          ?.map(
                            (item: any) => `<tr>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">${
                            item.title
                          }</td>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">1</td>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">${formatMoney(
                            item.price
                          )}</td>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">${formatMoney(
                            (item.price * item.discountPercentage) / 100
                          )}</td>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">${formatMoney(
                            (item.price * item.tax) / 100
                          )}</td>
                          <td style="padding: 8px; border: 1px solid #e2e8f0;">${formatMoney(
                            item.price -
                              (item.price * item.discountPercentage) / 100 +
                              (item.price * item.tax) / 100
                          )}</td>
                      </tr>`
                          )
                          .join("")}
                  </tbody>
              </table>
    `,
  });

  useEffect(() => {
    setContent(dynamicContent);
  }, [dynamicContent]);

  useEffect(() => {
    const companyInfoSetting = settings.find(
      (setting: { key: string }) => setting.key === "companyInfo"
    );
    console.log(companyInfoSetting);
    if (companyInfoSetting) {
      setCompInfo(companyInfoSetting.value);
    }
  }, [settings]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="mx-auto bg-white rounded container shadow my-8 overflow-y-scroll max-h-[90vh]">
        <Typography variant="h6" className="text-center p-4">
          {agreement?.title}
        </Typography>
        <div
          className="px-4 py-2 "
          dangerouslySetInnerHTML={{ __html: dynamicContent }}
        />
      </div>
    </Modal>
  );
}
