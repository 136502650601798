import { getAll } from "@/actions/setting.action";
import FictionalApplyForm from "@/components/home/FictionalApplyForm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentComponent from "./ContentComponent";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";

export default function AboutFictionalEvent() {
  const dispatch = useDispatch();
  const { settings, status } = useSelector((state: any) => state.settings);
  const [about, setAbout] = useState<any>(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  useEffect(() => {
    const about = settings.find(
      (setting: any) => setting.key === "aboutFidctionalEducation"
    );
    if (settings) {
      setAbout(about?.value);
    }
  }, [settings]);

  return (
    <div className="w-full justify-items-center my-8 flex gap-8">
      <ContentComponent about={about} className="md:col-span-7" />
      <div className="md:col-span-5">
        <FictionalApplyForm />
        <div className="flex flex-col gap-4 bg-[#fce49f] h-fit rounded shadow p-8 my-4">
          <h2 className="text-xl font-bold ">Bizimle İletişime Geçin</h2>
          <div className="w-full rounded p-4">
            <div className="flex flex-col gap-4 justify-center">
              <div className="flex">
                <FaMapMarkerAlt className="text-indigo-500 text-2xl mr-4" />
                <span>
                  Fethiye Mh. Sanayi Cd.CornerPlus İş Merkezi No:263/a
                  Nilüfer/BURSA
                </span>
              </div>
              <div className="flex">
                <FaPhone className="text-indigo-500 text-2xl mr-4" />
                <span>+90 (224) 234 02 37 - (538) 605 83 30</span>
              </div>
              <div className="flex">
                <FaEnvelope className="text-indigo-500 text-2xl mr-4" />
                <span>info@saymedya.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
