import { clearWhistList, removeWhistList } from "@/actions/whisList.action";
import { CourseDto } from "@/data/dtos/course.dto";
import { formatMoney, url } from "@/utils";
import {
  faCartShopping,
  faHeart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function WhisList() {
  const dispatch = useDispatch();
  const whistList = useSelector((state: any) => state.whisList);
  const [showListItems, setListItems] = useState(false);

  const handleClearWhistList = () => {
    dispatch(clearWhistList() as any);
  };

  const handleRemoveFromWhistList = (course) => {
    dispatch(removeWhistList(course) as any);
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setListItems(true)}
      onMouseLeave={() => setListItems(false)}
    >
      <div className="relative inline-flex items-center p-3 text-sm font-medium text-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300">
        <FontAwesomeIcon
          className="text-red-500 hover:text-red-700 w-6 h-6"
          icon={faHeart}
        />
        <span className="sr-only">Notifications</span>
        {whistList?.items && (
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1">
            {whistList?.items?.length}
          </div>
        )}
      </div>

      {/* Cart items list */}
      {showListItems && (
        <div className="absolute z-50 w-72 bg-white p-4 rounded-lg shadow-lg top-full right-0 border border-gray-200">
          <h2 className="text-lg font-semibold mb-2 text-gray-700 text-center">
            İstek Listem
          </h2>
          <hr className="mb-3" />
          <ul className="max-h-48 overflow-y-auto">
            {whistList?.items?.map((item: CourseDto, index: number) => (
              <li key={index} className="p-2 border-b last:border-b-0">
                <div className="flex gap-3 items-center">
                  <div className="w-12 h-12 rounded overflow-hidden border border-gray-300">
                    <img
                      src={item.images?.[0]?.url ?? ""}
                      alt={item.images?.[0]?.altText ?? ""}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="flex">
                      <h2 className="text-sm font-medium text-gray-800">
                        {item.title}
                      </h2>
                    </div>
                    <div>
                      <button
                        className="text-red-500 hover:text-red-600"
                        onClick={() => handleRemoveFromWhistList(item)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-3 text-right flex justify-between">
            <button
              onClick={handleClearWhistList}
              className="text-red-500 hover:text-red-600 font-medium text-sm"
            >
              İstek Listesini Temizle
            </button>
            {/* <Link
              to={url("Whist-List")}
              className="text-blue-500 hover:text-blue-600 font-medium text-sm"
            >
              İstek Listem
            </Link> */}
          </div>
        </div>
      )}
    </div>
  );
}
