import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import {
  getAll,
  getByInstructer,
  getCourseBySlug,
} from "../../../actions/courses.actions";
import LiveClassComponent from "./liveClassComponents/LiveClassComponent";
import QuizComponent from "./QuizComponent";
import VideoComponent from "./VideoComponent";
import Reviews from "@/components/courses/Reviews";
import { ResourceType } from "@/data/enums/resource-type.enum";
import { CourseDto } from "@/data/dtos/course.dto";
import { ResourceDto } from "@/data/dtos/resource.dto";
import CourseMain from "@/components/courses/CourseMain";
import CourseSections from "@/components/courses/CourseSections";
import { UserData } from "@/data/dtos/user.dto";
import InstructorSection from "@/components/courses/CourseIntructerSection";
import { getSectionsByCourse } from "@/actions/serctions.action";

const ResourceModal = ({ isOpen, resource, onClose }: any) => {
  const renderResourceComponent = () => {
    if (!resource) return <Typography>Lütfen bir kaynak seçin.</Typography>;
    switch (resource.type) {
      case ResourceType.VIDEO:
        return <VideoComponent currentResource={resource} />;
      case ResourceType.QUIZZ:
        return <QuizComponent quiz={resource.quizz!} />;
      case ResourceType.LIVE_CLASS:
        return <LiveClassComponent resource={resource} />;
      default:
        return null;
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="container mx-auto mt-4 bg-white rounded shadow h-auto p-8 relative">
        <Box>{renderResourceComponent()}</Box>
        <button
          className="absolute top-2 right-2 bg-red-500 text-white rounded w-8 h-8 flex items-center justify-center"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </Modal>
  );
};

export default function CourseDetail({ courseData }: any) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { currentCourse } = useSelector((state: any) => state.courses);
  const { sections } = useSelector((state: any) => state.sections);
  const [currentSections, setCurrentSections] = useState([]);
  const [course, setCourse] = useState<CourseDto>();
  const [currentResource, setCurrentResource] = useState<ResourceDto | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log(slug);
    if (slug) {
      Promise.all([getCourseBySlug(slug), getByInstructer(slug)]).then(
        (actions) => {
          actions.forEach((action) => action(dispatch));
        }
      );
    } else {
      courseData && setCourse(courseData);
    }
  }, [dispatch, slug]);

  useEffect(() => {
    setCourse(currentCourse);
  }, [currentCourse]);

  useEffect(() => {
    if (currentCourse?._id) {
      dispatch(getSectionsByCourse(currentCourse._id) as any);
      setCurrentSections(currentCourse._id);
    }
  }, [dispatch, currentCourse]);

  useEffect(() => {
    setCurrentSections(sections);
  }, [sections]);

  return (
    <div className="container mx-auto p-4">
      <div className="">
        <div className="container mx-auto px-4 py-8">
          <CourseMain course={course!} sections={currentSections} />

          {/* Sections */}
          <CourseSections
            sections={currentSections}
            setCurrentResource={setCurrentResource}
          />

          <hr />
          {/* Instructors */}
          <InstructorSection instructors={course?.instructors as UserData[]} />
          <hr />
          <Reviews reviews={course?.reviews} />
        </div>
      </div>
      {/* Resource Modal */}
      <ResourceModal
        isOpen={isOpen}
        resource={currentResource}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}
