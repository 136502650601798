import Footer from "@/components/core/Footer";
import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "./Nav";

export default function PolicyLayout() {
  return (
    <div className="flex flex-col min-h-screen bg-[#81BFDA]  relative">
      {/* Header */}
      <Nav />

      {/* Main Content */}
      <main className="flex-grow mt-24">
        <Outlet></Outlet>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
