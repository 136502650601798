import React, { createContext, useContext, useState } from "react";

interface MetaContextProps {
  meta: {
    title: string;
    description: string;
  };
  setMeta: React.Dispatch<
    React.SetStateAction<{ title: string; description: string }>
  >;
}

const MetaContext = createContext<MetaContextProps | undefined>(undefined);

export const MetaProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [meta, setMeta] = useState({
    title: "Varsayılan Başlık",
    description: "Varsayılan Açıklama",
  });

  return (
    <MetaContext.Provider value={{ meta, setMeta }}>
      {children}
    </MetaContext.Provider>
  );
};

export const useMeta = () => {
  const context = useContext(MetaContext);
  if (!context) {
    throw new Error("useMeta must be used within a MetaProvider");
  }
  return context;
};
