import { deepClone } from "@mui/x-data-grid/internals";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
} from "../actions/type";

interface AuthState {
  user: any;
  token: string;
  loading: boolean;
  error: string | any;
  isLoggedIn: boolean;
  rememberMe: boolean;
}

const rememberMeValue =
  localStorage.getItem("rememberMe") === "true" ? true : false;

const initialState: AuthState = {
  user: null,
  token: "",
  loading: false,
  error: null,
  isLoggedIn: false,
  rememberMe: rememberMeValue,
};

interface AuthAction {
  type: string;
  payload?: any;
}

export default function authReducer(
  state = initialState,
  action: AuthAction
): AuthState {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user ?? "",
        token: payload.access_token,
        rememberMe: false,
      };

    case REGISTER_FAIL:
      return { ...state, isLoggedIn: false };

    case LOGIN_SUCCESS:
      console.log(payload);
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user ?? {
          id: 1,
          username: "Jhon Doe",
          email: "johndoe@example.com",
        },
        token: payload.access_token,
        rememberMe: payload.remember ?? false,
      };

    case "UPDATE_STATE":
      console.log(payload);
      return {
        ...state,
        user: deepClone(payload),
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        rememberMe: false,
        token: "",
      };

    case "CREATE_CV_SUCCESS":
    case "UPDATE_CV_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          cv: payload,
        },
      };

    case "DELETE_CV_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          cv: null,
        },
      };

    case "UPDATE_COURSE_PROGRESS_SUCCESS":
    case "MARK_RESOURCE_AS_COMPLETED_SUCCESS":
    case "CHECK_SECTION_COMPLETION_SUCCESS": {
      const updatedCourseProgress = payload.data; // Gelen güncellenmiş veriler

      console.log(
        "Payload completedSections:",
        updatedCourseProgress.completedSections
      );

      return {
        ...state,
        user: {
          ...state.user,
          userCourseProgress: state.user.userCourseProgress.map(
            (courseProgress) => {
              // İlgili kursun `completedSections`'ını güncelle
              if (courseProgress._id === updatedCourseProgress._id) {
                console.log(
                  "Updating course progress for ID:",
                  updatedCourseProgress._id
                );

                // Tüm progress'i güncellemek yerine sadece `completedSections`'ı güncelle
                return {
                  ...courseProgress,
                  completedSections:
                    updatedCourseProgress.completedSections.map(
                      (updatedSection) => {
                        // Mevcut `completedSections` içinde eşleşen section'ı bul ve güncelle
                        const existingSection =
                          courseProgress.completedSections.find(
                            (section) =>
                              section.sectionId === updatedSection.sectionId
                          );

                        if (existingSection) {
                          return {
                            ...existingSection,
                            resources: updatedSection.resources,
                            isCompleted: updatedSection.isCompleted,
                          };
                        }

                        // Eğer mevcut bir `section` yoksa, yeni section'ı ekle
                        return updatedSection;
                      }
                    ),
                };
              }
              return courseProgress; // Diğer kurslar olduğu gibi kalsın
            }
          ),
        },
      };
    }

    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        rememberMe: false,
        token: "",
      };

    default:
      return state;
  }
}
