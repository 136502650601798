import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "./type";
import authService from "../services/auth.service";
import { setError, setMessage } from "./message";
import { CvDto } from "@/data/dtos/cv.dto";

interface LoginPayloadInterface {
  email: string;
  password: string;
  remember: boolean;
}

interface RegisterPayloadInterface {
  email: string;
  password: string;
  name: string;
  surname: string;
  role: string;
  contents: any;
}

export const login =
  (payload: LoginPayloadInterface) => async (dispatch: any) => {
    dispatch({ type: LOGIN_REQUEST });

    await authService.login(payload).then(
      (response) => {
        dispatch({ type: LOGIN_SUCCESS, payload: response!.data });
        dispatch(setMessage(response!.data.message, 5000));

        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        const message = error.response!.data.message;
        dispatch({ type: "LOGIN_FAIL" });
        dispatch(setError(message));
        return Promise.reject();
      }
    );
  };

export const register =
  (payload: RegisterPayloadInterface) => async (dispatch: any) => {
    dispatch({ type: REGISTER_REQUEST });

    await authService.register(payload).then(
      (response) => {
        dispatch({ type: "REGISTER_SUCCESS", payload: response!.data });
        dispatch(setMessage(response!.data.message, 5000));
        console.log(response!.data);
        return Promise.resolve();
      },
      (error) => {
        const message = error.response!.data;
        dispatch({ type: "REGISTER_FAIL" });
        dispatch(setError(message));
        return Promise.reject();
      }
    );
  };

export const forgotPassword = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  await authService
    .forgotPassword(payload)
    .then((response) => {
      dispatch({ type: "FORGOT_PASSWORD_SUCCESS", payload: response!.data });
      dispatch(setMessage(response!.data.message, 5000));
      return Promise.resolve();
    })
    .catch((error) => {
      const message = error.response!.data;
      dispatch({ type: "FORGOT_PASSWORD_FAIL" });
      dispatch(setError(message));
      return Promise.reject();
    });
};

export const resetPassword = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  await authService
    .resetPassword(payload)
    .then((response) => {
      console.log(response);
      dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: response!.data });
      dispatch(setMessage(response!.data.message, 5000));
      return Promise.resolve();
    })
    .catch((error) => {
      console.log(error);
      const message = error.response!.data;
      console.log(message);
      dispatch({ type: "RESET_PASSWORD_FAIL" });
      dispatch(setError(message));
      return Promise.reject();
    });
};

export const updateState = (token: string) => async (dispatch: any) => {
  console.log("auth action");
  await authService
    .updateState(token)
    .then((response) => {
      console.log(response);
      dispatch({
        type: "UPDATE_STATE",
        payload: response,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const logout = (sessionId: string) => (dispatch: any) => {
  authService.logout(sessionId);

  dispatch({
    type: LOGOUT,
  });
  dispatch(setMessage("You have been logged out", 5000));
};

export const createCv = (payload: CvDto) => async (dispatch: any) => {
  await authService
    .createCv(payload)
    .then((response) => {
      console.log(response);
      dispatch({ type: "CREATE_CV_SUCCESS", payload: response!.data });
      dispatch(setMessage("CV oluşturuldu", 5000));
      return Promise.resolve(response);
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError(error.response!.data));
      throw new Error(error);
    });
};

export const updateCv =
  (id: string, payload: CvDto) => async (dispatch: any) => {
    await authService
      .updateCv(id, payload)
      .then((response) => {
        console.log(response);
        dispatch({ type: "UPDATE_CV_SUCCESS", payload: response!.data });
        dispatch(setMessage("CV güncellendi", 5000));
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(error.response!.data));
        throw new Error(error);
      });
  };

export const deleteCv = (id: string) => async (dispatch: any) => {
  await authService
    .removeCv(id)
    .then((response) => {
      dispatch({ type: "DELETE_CV_SUCCESS", payload: response!.data });
      dispatch(setMessage("CV silindi", 5000));
      return Promise.resolve(response);
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError(error.response!.data));
      throw new Error(error);
    });
};

export const updateCourseProgress =
  (userId: string, courseId: string, payload: any) => async (dispatch: any) => {
    await authService
      .updateCourseProgress(userId, courseId, payload)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "UPDATE_COURSE_PROGRESS_SUCCESS",
          payload: response!.data,
        });
        dispatch(setMessage("Eğitim davamlığı güncellendi", 5000));
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(error.response!.data));
        throw new Error(error);
      });
  };

export const markResourceAsComplated =
  (
    userId: string,
    courseId: string,
    sectionId: string,
    resourceId: string,
    payload: any
  ) =>
  async (dispatch: any) => {
    await authService
      .markResourceAsComplated(userId, courseId, sectionId, resourceId, payload)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "MARK_RESOURCE_AS_COMPLETED_SUCCESS",
          payload: response!.data,
        });
        dispatch(setMessage("Eğitim davamlığı güncellendi", 5000));
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(error.response!.data));
        throw new Error(error);
      });
  };

export const checkSectionCompletion =
  (userId: string, courseId: string, sectionId: string, payload: any) =>
  async (dispatch: any) => {
    await authService
      .checkSectionCompletion(userId, courseId, sectionId, payload)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "CHECK_SECTION_COMPLETION_SUCCESS",
          payload: response!.data,
        });
        dispatch(setMessage("Eğitim davamlısı kontrol edildi", 5000));
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(error.response!.data));
        throw new Error(error);
      });
  };
