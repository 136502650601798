import React from "react";

export default function ContentComponent({ about, className }: any) {
  return (
    <section
      className={
        `py-8 relative bg-[#a8def5] px-8 rounded shadow md:max-w-5xl lg:w-3/4 ` +
        className
      }
    >
      <div className="flex items-center max-w-6xl mx-auto ">
        <div className="w-full py-4 px-12 order-1 justify-items-center">
          <div className="text text-justify">
            <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl mb-2 hidden ">
              {about?.title}
            </h1>

            <div
              className=""
              dangerouslySetInnerHTML={{ __html: about?.content }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}
