import { CourseDto } from "@/data/dtos/course.dto";
import { createContext, ReactNode, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "./UserContext";
import { addToCart } from "@/actions/cart.actions";
import { addToWhistList } from "@/actions/whisList.action";

interface CourseCardContextValue {
  cart: any;
  user: any; // Replace with your User type if defined
  handleAddToCart: (course: CourseDto) => void;
  handleAddToWhisList: (course: CourseDto) => void;
}

// Props for the provider
interface CourseCardProviderProps {
  children: ReactNode;
}

// Props for the Card component
interface CardProps {
  course: CourseDto;
}

const CourseCardContext = createContext<CourseCardContextValue | undefined>(
  undefined
);

export const CourseCardProvider: React.FC<CourseCardProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  const handleAddToCart = (course: CourseDto) =>
    dispatch(addToCart(course) as any);
  const handleAddToWhisList = (course: CourseDto) =>
    dispatch(addToWhistList(course) as any);

  return (
    <CourseCardContext.Provider
      value={{
        cart,
        user,
        handleAddToCart,
        handleAddToWhisList,
      }}
    >
      {children}
    </CourseCardContext.Provider>
  );
};

export const useCourseCardContext = (): CourseCardContextValue => {
  const context = useContext(CourseCardContext);
  if (!context) {
    throw new Error(
      "useCourseCardContext must be used within a CourseCardProvider"
    );
  }
  return context;
};
