import React, { useState, useEffect } from "react";

export default function Chat({ user, currentCourse, socket, users }) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any[]>([]);

  const sendMessage = () => {
    if (socket) {
      console.log("current course", currentCourse?._id);
      socket.emit("sendMessage", {
        roomName: currentCourse?._id,
        user: { id: user._id, name: user.name, avatar: user.avatar },
        message,
      });
      setMessage("");
    }
  };

  useEffect(() => {
    // Yeni bir mesaj geldiğinde dinleme
    const handleReceiveMessage = (message) => {
      console.log(message);
      setMessages((prevMessages) => [...prevMessages, message]);
    };
    console.log(socket);
    // Sadece bir defa "receiveMessage" olayını dinle
    socket?.on("receiveMessage", handleReceiveMessage);

    return () => {
      socket?.off("receiveMessage", handleReceiveMessage);
    };
  }, [socket, currentCourse]);

  return (
    <div className="w-full me-3 flex flex-col gap-3 h-screen">
      <div className="rounded border-2 border-slate-950 bg-white h-[65vh] overflow-y-scroll p-2">
        <ul className="grid grid-cols-1 gap-2">
          {messages.map((msg, index) => {
            const isCurrentUser = msg.user.id === user._id;
            return (
              <li
                key={index}
                className={`flex w-full gap-2 items-center ${
                  isCurrentUser ? "justify-end" : "justify-start"
                }`}
              >
                {msg.user.avatar && !isCurrentUser && (
                  <img
                    src={msg.user.avatar}
                    alt={msg.user.name}
                    className="rounded-full w-10 h-10"
                  />
                )}
                <div
                  className={`flex flex-col ${
                    isCurrentUser ? "items-end" : "items-start"
                  }`}
                >
                  <strong>{msg.user.name}:</strong> {msg.message}
                </div>
                {msg.user.avatar && isCurrentUser && (
                  <img
                    src={msg.user.avatar}
                    alt={msg.user.name}
                    className="rounded-full w-10 h-10"
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="w-full flex justify-between gap-2">
        <input
          type="text"
          className="border-2 border-slate-950 bg-white w-full rounded-l"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={sendMessage}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Gönder
        </button>
      </div>
    </div>
  );
}
