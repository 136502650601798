import React, {
  useRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faHeart as faSolidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import { formatMoney, url } from "@/utils";
import { UserContext } from "@/context/UserContext";
import { Link } from "react-router-dom";
import { CourseDto } from "@/data/dtos/course.dto";
import { addToCart } from "@/actions/cart.actions";
import { addToWhistList, removeWhistList } from "@/actions/whisList.action";

const Card: React.FC<{ course: CourseDto }> = ({ course }) => {
  const dispatch = useDispatch();
  const { cart, whisList, user } = useSelector(
    (state: any) => ({
      cart: state.cart,
      whisList: state.whisList,
      user: state.auth.user,
    }),
    (prev, next) =>
      prev.cart === next.cart &&
      prev.whisList === next.whisList &&
      prev.user === next.user
  );

  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const isInCart = useMemo(
    () => cart?.items?.some((item) => item._id === course._id),
    [cart, course]
  );
  const isInWishlist = useMemo(
    () => whisList?.items?.some((item) => item._id === course._id),
    [whisList, course]
  );
  const isOwnedByUser = useMemo(
    () =>
      user?.userCourseProgress?.some(
        (crs: any) => crs.course._id === course._id
      ),
    [user, course]
  );

  const handleAddToCart = useCallback(
    () => dispatch(addToCart(course) as any),
    [dispatch, course]
  );
  const handleToggleWishlist = useCallback(
    () =>
      isInWishlist
        ? dispatch(removeWhistList(course) as any)
        : dispatch(addToWhistList(course) as any),
    [dispatch, course, isInWishlist]
  );

  return (
    <div className="bg-white shadow-md rounded-md group transition-all hover:shadow-lg hover:scale-105 max-h-[24rem]">
      {/* Image Section */}
      <Link
        to={url("Courses.Course-By-Slug", { slug: course.slug! })}
        className="block"
      >
        <div
          className="h-40 sm:h-48 w-full bg-gray-200 rounded-t-md overflow-hidden transition-transform duration-300"
          style={{
            backgroundImage: `url("${course.images![0].url}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </Link>

      {/* Content Section */}
      <div className="p-4 flex flex-col justify-between bottom-0 h-auto">
        <div>
          {/* Instructor Tags */}
          <div className="text-sm text-gray-400 flex flex-wrap gap-1 mb-2">
            {course.instructors?.map((instructor, index) => (
              <span key={index} className="bg-gray-100 px-2 py-1 rounded-full">
                {instructor.name}
              </span>
            ))}
          </div>

          {/* Title with Truncation */}
          <Link to={url("Courses.Course-By-Slug", { slug: course.slug })}>
            <h2
              className="text-md font-semibold text-gray-800 group-hover:text-blue-500 transition block truncate"
              style={{
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={course.title} // Full title as tooltip
            >
              {course.title}
            </h2>
          </Link>

          {/* Price Section */}
          <div className="flex justify-between items-center w-full mt-2">
            <p className="text-md font-semibold text-gray-800">
              {formatMoney(
                course.price - (course.price * course.discountPercentage) / 100
              )}
            </p>
            {course.discountPercentage > 0 && (
              <p className="text-sm text-gray-500 line-through">
                {formatMoney(course.price)}
              </p>
            )}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between items-center w-full mt-4">
          {isOwnedByUser ? (
            <p className="text-green-600 text-sm font-medium">Owned</p>
          ) : (
            <>
              <button
                onClick={handleAddToCart}
                className={`px-3 py-1 text-sm font-semibold rounded-md transition text-white ${
                  isInCart
                    ? "bg-red-600 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                disabled={isInCart}
              >
                {isInCart ? "Sepette" : "Sepete Ekle"}
                <FontAwesomeIcon icon={faShoppingCart} className="ml-2" />
              </button>
              <button
                onClick={handleToggleWishlist}
                className="text-red-500 transition hover:text-red-600"
                aria-label="Favoriye Ekle"
              >
                <FontAwesomeIcon
                  icon={isInWishlist ? faSolidHeart : faRegularHeart}
                  className={isInWishlist ? "animate-pulse" : undefined}
                />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
