import { get } from "@/actions/mertrics.action";
import { getAll } from "@/actions/orders.actions";
import Chart from "@/components/auth/admin/dashboard/Chart";
import OrdersChart from "@/components/auth/admin/dashboard/OrdersChart";
import ReportCard from "@/components/auth/admin/dashboard/ReportCard";
import { UserContext } from "@/context/UserContext";
import { CourseDto } from "@/data/dtos/course.dto";
import { OrderData } from "@/data/dtos/order.dto";
import { OrderStatus } from "@/data/enums/order-status.enum";
import {
  faBox,
  faDollar,
  faEye,
  faMessage,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const [relatedOrders, setRelatedOrders] = useState<OrderData[]>([]);
  const { metrics, status } = useSelector((state: any) => state.metrics);
  const { orders, listStatus } = useSelector((state: any) => state.order);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  // Fetch orders if listStatus is idle
  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(getAll() as any);
    }
  }, [listStatus, dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(get() as any);
    }
  }, [status, dispatch]);

  // Filter related orders based on user role
  useEffect(() => {
    const filterRelatedOrders = () => {
      if (!orders.length || !user?._id) return [];

      if (user.role === "teacher") {
        return orders.filter(
          (order: OrderData) =>
            order.status === OrderStatus.PAID &&
            order.items?.some((item: any) =>
              item.course.instructors?.includes(user._id)
            )
        );
      }

      // Return all orders for non-teacher roles
      return orders;
    };

    setRelatedOrders(filterRelatedOrders());
  }, [orders, user]);

  // Chart data
  const data = metrics.weeklyWageList;

  return (
    <div className="container mx-auto rounded bg-white flex flex-col p-4">
      <h1 className="justify-center w-full text-center text-xl font-semibold">
        Hoş Geldiniz, {user?.name ?? "Guest"}!
      </h1>
      {/* Metrics Grid */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 my-4">
        {metrics?.metrics?.map((metric) => (
          <ReportCard metric={metric} key={metric.id} />
        ))}
      </div>
      {/* Chart Section */}
      <Chart data={data} />
      {/* Orders Section */}
      <OrdersChart recentOrders={relatedOrders} />
    </div>
  );
}
