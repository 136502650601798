import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { QuizzDto } from "@/data/dtos/quizz.dto";

interface QuizComponentProps {
  quiz: QuizzDto;
  onComplete?: (successRate: number) => void; // Başarı oranını döndüren callback
}

const QuizComponent: React.FC<QuizComponentProps> = ({ quiz, onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [userAnswers, setUserAnswers] = useState<number[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [showResults, setShowResults] = useState<boolean>(false);

  const currentQuestion = quiz.questions[currentQuestionIndex];

  // Yeni quiz yüklendiğinde state sıfırla
  useEffect(() => {
    resetQuizState();
  }, [quiz]);

  const resetQuizState = () => {
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setSelectedOption(null);
    setShowResults(false);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(event.target.value, 10));
  };

  const handleConfirmAnswer = () => {
    if (selectedOption === null) {
      alert("Lütfen bir seçenek seçin.");
      return;
    }

    const updatedAnswers = [...userAnswers, selectedOption];
    setUserAnswers(updatedAnswers);

    // Sonraki soruya geç veya sonucu göster
    if (currentQuestionIndex + 1 < quiz.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null); // Seçimi sıfırla
    } else {
      setShowResults(true);
      handleQuizCompletion(updatedAnswers);
    }
  };

  const handleQuizCompletion = (answers: number[]) => {
    const { correctCount, total } = calculateResults(answers);
    const successRate = (correctCount / total) * 100;

    if (onComplete) {
      onComplete(successRate); // Başarı oranını döndür
    }
  };

  const calculateResults = (answers: number[]) => {
    const correctCount = quiz.questions.reduce((count, question, index) => {
      return answers[index] === question.correctAnswer ? count + 1 : count;
    }, 0);

    return { correctCount, total: quiz.questions.length };
  };

  if (showResults) {
    const { correctCount, total } = calculateResults(userAnswers);

    return (
      <div>
        {/* //TODO :  when course complate */}
        <Typography variant="h5">Sonuçlar</Typography>
        <br />
        <Typography variant="body1">
          Doğru Sayısı : {correctCount} / {total}
        </Typography>
        <Typography variant="body1">
          Başarı Oranı : %{(correctCount / total) * 100}
        </Typography>
        {quiz.questions.map((question, index) => (
          <div key={index}>
            <Typography variant="subtitle1">
              {index + 1}. {question.question}
            </Typography>
            <Typography
              variant="body2"
              color={
                userAnswers[index] === question.correctAnswer ? "green" : "red"
              }
            >
              Sizin cevabınız: {question.options[userAnswers[index]]}
            </Typography>
            <Typography variant="body2" color="green">
              Doğru cevap: {question.options[question.correctAnswer]}
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h6">
        {currentQuestionIndex + 1}. {currentQuestion.question}
      </Typography>
      <RadioGroup
        value={selectedOption !== null ? selectedOption.toString() : ""}
        onChange={handleOptionChange}
      >
        {currentQuestion.options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            value={idx.toString()}
            control={<Radio />}
            label={`${String.fromCharCode(65 + idx)}. ${option}`}
          />
        ))}
      </RadioGroup>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={handleConfirmAnswer}
      >
        Onayla
      </Button>
    </div>
  );
};

export default QuizComponent;
