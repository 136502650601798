import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "@/context/UserContext";
import { UserData } from "@/data/dtos/user.dto";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import { create, upload } from "@/actions/image.actions";
import { ImageDto } from "@/data/dtos/image.dto";
import { CategoryData } from "@/data/dtos/category.dto";
import InputError from "@/components/core/InputError";
import message from "@/reducers/message";
import { MuiTelInput } from "mui-tel-input";
import { changePassword, update } from "@/actions/users.actions";
import { setError } from "@/actions/message";
import { ChangePasswordDto } from "@/data/dtos/change-password.dto";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditProfile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const { URL } = useSelector((state: any) => state.uploads);
  const { currentImage } = useSelector((state: any) => state.images);
  const { message } = useSelector((state: any) => state.message);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [photoName, setPhotoName] = useState<string | null>(null);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user } = userContext;
  console.log("user", user);
  const {
    _id,
    __v,
    resetToken,
    createdAt,
    updatedAt,
    addresses,
    billingAddresses,
    courses,
    orders,
    archivedCourses,
    userCourseProgress,
    job,
    session,
    agreements,
    ...filteredData
  } = user!;

  const [formData, setFormData] = useState<UserData>(
    filteredData || {
      name: "",
      surname: "",
      email: "",
      password: "",
      profileImage: "",
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(upload(formData) as any);
      setPhotoName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPhotoPreview(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    if (id) {
      dispatch(update(id, formData) as any);
    } else {
      dispatch(setError("Kullanıcı bulunamadı"));
    }
  };

  useEffect(() => {
    if (URL) {
      console.log("url change", URL);
      const imageData: ImageDto = {
        altText: formData.name ?? "",
        url: URL,
        isOnUse: true,
      };
      dispatch(create(imageData) as any);
    }
  }, [URL, dispatch]);

  useEffect(() => {
    if (currentImage) {
      console.log();
      setFormData((prev: UserData) => ({
        ...prev,
        profileImage: currentImage.url,
      }));
    }
  }, [currentImage]);

  return (
    <div>
      <Container maxWidth="sm" className="mt-4 rounded shadow p-4 bg-white">
        <Typography variant="h5" component="h1" gutterBottom>
          Profilimi Düzenle {user?.name}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} className="text-center">
              {/* Varsayılan veya Yüklenen Profil Fotoğrafı */}
              {!photoPreview ? (
                <img
                  src={user?.profileImage}
                  alt="Current Profile"
                  className="w-40 h-40 m-auto rounded-full shadow"
                />
              ) : (
                <div
                  className="w-40 h-40 m-auto rounded-full shadow"
                  style={{
                    backgroundImage: `url(${photoPreview})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              )}
              {/* Fotoğraf İsmi */}
              {photoName && (
                <Typography variant="caption" className="block mt-2">
                  {photoName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className="text-center">
              {/* Fotoğraf Yükleme Butonu */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                component="label"
              >
                Yeni Fotoğraf Seç
                <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ad"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                variant="outlined"
              />
              <InputError messages={message?.errors?.name} className="mt-2" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Soyad"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                variant="outlined"
              />
              <InputError
                messages={message?.errors?.surname}
                className="mt-2"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="E-Posta"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                variant="outlined"
              />
              <InputError messages={message?.errors?.email} className="mt-2" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Kimlik Numarası (TC No)"
                name="identityNumber"
                value={formData.identityNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
              <InputError
                messages={message?.errors?.identityNumber}
                className="mt-2"
              />
            </Grid>
            <Grid item xs={12}>
              <MuiTelInput
                label="Telefon"
                variant="outlined"
                className="col-span-2"
                fullWidth
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e })}
              />
              <InputError messages={message?.errors?.phone} className="mt-2" />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Bilgileri Kaydet
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
