import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CourseCard from "./CourseCard";
import Header from "@/components/core/Header";
import { UserContext } from "@/context/UserContext";
import { Link } from "react-router-dom";
import { url } from "@/utils";

interface TabPanelProps {
  readonly children?: React.ReactNode;
  readonly index: number;
  readonly value: number;
}

function TabPanel({ children, value, index }: TabPanelProps) {
  if (value !== index) return null;
  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ p: 3 }}
    >
      {children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyCourses() {
  const [value, setValue] = useState(0);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  const { user } = userContext;

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderCourseList = () => {
    if (!user?.userCourseProgress || user.userCourseProgress.length === 0) {
      return (
        <div className="col-span-full text-center text-gray-500">
          <p>Atölye İçeriği Yok</p>
          <Link
            to={url("Home.Workshops")}
            className="mt-4 px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
          >
            Yeni Atölye Edinin
          </Link>
        </div>
      );
    }

    return user.userCourseProgress.map((detail: any) => (
      <CourseCard
        key={detail.course._id}
        loading={false}
        courseDetail={detail}
      />
    ));
  };

  return (
    <div>
      <Header title="Atölye İçeriklerim" />
      <div className="container mx-auto max-w-7xl mt-4">
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="My Courses Tabs"
          >
            <Tab label="DEVAM EDEN ATÖLYELER" {...a11yProps(0)} />
            <Tab label="ABONELİK İÇERİĞİM" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        <TabPanel value={value} index={0}>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {renderCourseList()}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="text-center text-gray-500">Abonelik İçeriği</div>
        </TabPanel>
      </div>
    </div>
  );
}
