import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "@/actions/orders.actions";
import { getAll as getAllCourses } from "@/actions/courses.actions";
import { formatMoney } from "@/utils";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { parse } from "path";
import { OrderData } from "@/data/dtos/order.dto";
import { OrderStatus } from "@/data/enums/order-status.enum";
import { get } from "@/actions/users.actions";
import CoursePerformanceTable from "@/components/auth/admin/user/CoursePerformanceTable";
import CourseSalesTable from "@/components/auth/admin/user/CourseSalesTable";
import TeacherCvDisplay from "@/components/auth/admin/user/TeacherCvDisplay";

export default function TeacherPerformance() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { courses } = useSelector((state: any) => state.courses);
  const { orders } = useSelector((state: any) => state.order);
  const { currentUser: teacher } = useSelector((state: any) => state.users);

  useEffect(() => {
    if (orders.length <= 0) {
      dispatch(getAll() as any);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    if (courses.length <= 0) {
      dispatch(getAllCourses() as any);
    }
  }, [dispatch, courses]);

  useEffect(() => {
    dispatch(get(id!) as any);
  }, [dispatch, id]);

  // Öğretmenin Atölyelarını filtrele
  const teacherCourses = useMemo(() => {
    return courses.filter((course: any) =>
      course.instructors.some((instructorId) => instructorId._id === id)
    );
  }, [courses, id]);

  // Satış ve gelir bilgilerini hesapla
  const {
    totalSales,
    totalRevenue,
    averagePrice,
    topSellingCourse,
    topRevenueCourse,
    monthlySalesData,
    items,
    totalRevenueWithDiscount,
    groupedData,
    averagePriceWithDiscount,
  } = useMemo(() => {
    const items = orders
      .filter((order) => order.status === OrderStatus.PAID)
      .flatMap((order) =>
        order.items.filter((item) => item.course.instructors.includes(id))
      );

    console.log("items", items);

    const totalSales = items.reduce((sum, item) => sum + item.quantity, 0);
    const totalRevenue = items.reduce((sum, item) => sum + item.price, 0);
    const totalRevenueWithDiscount = items.reduce(
      (sum, item) => sum + item.price - item.discount,
      0
    );
    const averagePrice =
      teacherCourses.length > 0 ? totalRevenue / totalSales : 0;
    const averagePriceWithDiscount =
      teacherCourses.length > 0 ? totalRevenueWithDiscount / totalSales : 0;

    const groupedData = items.reduce((acc: any, item) => {
      const courseTitle = item.course.title;
      const coursePrice = item.course.price;
      const totalTax = item.tax;
      const totalDiscount = item.discount;

      // Daha önce eklenmişse, satışları ve gelirleri topla
      const existingEntry = acc.find(
        (entry: any) => entry.name === courseTitle
      );
      if (existingEntry) {
        existingEntry.sales += item.quantity;
        existingEntry.revenue += item.quantity * coursePrice;
        existingEntry.tax += item.tax;
        existingEntry.discount += item.discount;
      } else {
        acc.push({
          name: courseTitle,
          sales: item.quantity,
          revenue: item.quantity * coursePrice,
          tax: totalTax,
          discount: totalDiscount,
        });
      }

      return acc;
    }, []);

    const topRevenueCourse = groupedData.reduce(
      (prev, item) => (item.revenue > prev.revenue ? item : prev),
      { revenue: 0 }
    );

    const topSellingCourse = groupedData.reduce(
      (prev, item) => (item.sales > prev.sales ? item : prev),
      { sales: 0 }
    );

    const monthlySalesData = Array.from({ length: 12 }, (_, i) => ({
      month: new Date(2023, i).toLocaleString("tr", { month: "long" }),
      sales: 0,
      revenue: 0,
    }));

    const courseOrderMap = teacherCourses.reduce((map, course) => {
      map[course._id] = orders.filter(
        (order) =>
          order.status === OrderStatus.PAID &&
          order.items.some((item) => item.course._id === course._id)
      );
      return map;
    }, {} as { [courseId: string]: OrderData[] });

    console.log("Course Order Map:", courseOrderMap);

    const courseOrderMapEntries = Object.entries(courseOrderMap);
    monthlySalesData.forEach((monthData, index) => {
      for (const [courseId, orders] of courseOrderMapEntries as [any, any]) {
        const course = teacherCourses.find((course) => course._id === courseId);
        if (course) {
          const sales = orders.filter(
            (order) => new Date(order.createdAt).getMonth() === index
          ).length;
          const revenue = sales * course.price;
          monthData.sales += sales;
          monthData.revenue += revenue;
        }
      }
    });

    console.log("Monthly Sales Data:", monthlySalesData);
    return {
      totalSales,
      totalRevenue,
      averagePrice,
      topSellingCourse,
      topRevenueCourse,
      monthlySalesData,
      items,
      groupedData,
      totalRevenueWithDiscount,
      averagePriceWithDiscount,
    };
  }, [teacherCourses, orders]);
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg">
      <h1 className="text-center text-3xl my-4 font-bold">
        Eğitmen Performans Grafikleri
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="p-4 bg-primary-600 rounded shadow text-center">
          <h3 className="text-lg font-semibold text-white">Toplam Atölye</h3>
          <p className="text-xl font-bold text-white">
            {teacherCourses.length}
          </p>
        </div>
        <div className="p-4 bg-primary-600 rounded shadow text-center">
          <h3 className="text-lg font-semibold text-white">Toplam Satış</h3>
          <p className="text-xl font-bold text-white">{totalSales}</p>
        </div>
        <div className="p-4 bg-primary-600 rounded shadow text-center">
          <h3 className="text-lg font-semibold text-white">Toplam Gelir</h3>
          <p className="text-xl font-bold text-white">
            {formatMoney(totalRevenueWithDiscount)}
          </p>
        </div>
        <div className="p-4 bg-primary-600 rounded shadow text-center">
          <h3 className="text-lg font-semibold text-white">
            Ortalama Atölye Fiyatı
          </h3>
          <p className="text-xl font-bold text-white">
            {formatMoney(averagePriceWithDiscount)}
          </p>
        </div>
      </div>

      <div className="flex gap-4 m-auto">
        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">En Çok Satan Atölye</h2>
          <div className="p-4 bg-gray-100 rounded shadow">
            <p>
              <strong>Atölye Adı :</strong> {topSellingCourse.name}
            </p>
            <p>
              <strong>Satış Adedi :</strong> {topSellingCourse.sales}
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">
            En Yüksek Gelir Getiren Atölye
          </h2>
          <div className="p-4 bg-gray-100 rounded shadow">
            <p>
              <strong>Atölye Adı :</strong> {topRevenueCourse.name}
            </p>
            <p>
              <strong>Gelir :</strong> {formatMoney(topRevenueCourse.revenue)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <div className="mb-8 w-full">
          <h2 className="text-lg font-semibold mb-4">
            Atölye Satış Grafikleri
          </h2>
          <div className="p-4 bg-gray-100 rounded shadow">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={groupedData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="courseTitle" />
                <YAxis />
                <Tooltip formatter={(value) => value} />
                <Legend />
                <Bar dataKey="sales" fill="#4A90E2" name="Satış Adedi" />
                <Bar dataKey="revenue" fill="#50C878" name="Gelir" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="mb-8 w-full">
          <h2 className="text-lg font-semibold mb-4">Aylık Satış Dağılımı</h2>
          <div className="p-4 bg-gray-100 rounded shadow">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={monthlySalesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip formatter={(value) => value} />
                <Legend />
                <Bar dataKey="sales" fill="#4A90E2" name="Satış Adedi" />
                <Bar dataKey="revenue" fill="#50C878" name="Gelir" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div>
        <CoursePerformanceTable groupedData={groupedData} />
      </div>

      <div className="my-8">
        <CourseSalesTable items={items} />
      </div>

      <TeacherCvDisplay teacher={teacher} />
    </div>
  );
}
