import { Link, NavLink } from "react-router-dom";
import ApplicationLogo from "@/components/core/ApplicationLogo";
import NavList from "@/components/core/NavList";
import { url } from "@/utils";
import PrimaryButton from "@/components/PrimaryButton";
import SecondaryButton from "@/components/SecondaryButton";
import { useSelector } from "react-redux";
import Cart from "@/components/cart/Cart";
import Avatar from "@/components/Avatar";
import WhisList from "@/components/cart/WhisList";

const DashboardButton = ({ user }) => {
  const isAdminOrTeacher = user?.role === "admin" || user?.role === "teacher";

  return (
    <div className="flex gap-8 items-center">
      <div className="flex flex-row items-center">
        {!isAdminOrTeacher && (
          <>
            <Link to={url("Profile.My-Courses")}>Atölye İçeriğim</Link>
            <Cart />
            <WhisList />
          </>
        )}
      </div>
      {isAdminOrTeacher ? (
        <NavLink to={url("Admin.Dashboard")}>
          <PrimaryButton>YÖNETİM PANELİM</PrimaryButton>
        </NavLink>
      ) : (
        <Avatar />
      )}
    </div>
  );
};

const AuthButtons = () => (
  <div className="flex gap-4">
    <WhisList />
    <Cart />
    <NavLink to={url("Auth.Login")}>
      <PrimaryButton>Giriş Yap</PrimaryButton>
    </NavLink>
    <NavLink to={url("Auth.Register")}>
      <SecondaryButton>Kayıt Ol</SecondaryButton>
    </NavLink>
  </div>
);

export default function Nav() {
  const { isLoggedIn, user } = useSelector((state: any) => state.auth);

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-lg z-50 h-24">
      <nav className="relative px-4 flex justify-between items-center bg-white shadow-lg min-h-24">
        <ApplicationLogo className=" fill-current text-gray-500" />
        <NavList />
        {isLoggedIn ? <DashboardButton user={user} /> : <AuthButtons />}
      </nav>
    </header>
  );
}
