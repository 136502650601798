import { useLocationContext } from "@/context/LocationContext";
import React from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const { previousPath, currentPath } = useLocationContext();
  console.log(currentPath);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800">
      <h1 className="text-6xl font-bold text-indigo-600">404</h1>
      <h2 className="text-2xl font-semibold mt-4">Sayfa Bulunamadı</h2>
      <p className="text-gray-600 mt-2 text-center">
        Üzgünüz, aradığınız sayfa bulunamadı. Adres yanlış olabilir veya sayfa
        kaldırılmış olabilir.
      </p>
      <Link
        to="/"
        className="mt-6 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition"
      >
        Ana Sayfaya Dön
      </Link>
    </div>
  );
};

export default NotFound;
