import React, { useRef, useState } from "react";
import TextInput from "../../../../components/core/TextInput";
import InputLabel from "../../../../components/InputLabel";
import InputError from "../../../../components/core/InputError";
import PrimaryButton from "../../../../components/PrimaryButton";
import Checkbox from "../../../../components/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../actions/authAction";
import { url } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkBtn = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(
    localStorage.getItem("rememberMe") === "true"
  );
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const { message } = useSelector((state: any) => state.message);

  const validateForm = () => {
    const errors = { email: "", password: "" };
    let isValid = true;

    if (!email.trim()) {
      errors.email = "Email alanı boş bırakılamaz.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Geçerli bir email adresi giriniz.";
      isValid = false;
    }

    if (!password.trim()) {
      errors.password = "Şifre alanı boş bırakılamaz.";
      isValid = false;
    } else if (password.length < 6) {
      errors.password = "Şifre en az 6 karakter olmalıdır.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Prevent submission if validation fails
    }

    setLoading(true);
    try {
      await dispatch(login({ email, password, remember }) as any);
      navigate("/");
    } catch (err) {
      console.error("Login failed", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="rounded-lg w-full max-w-md mx-auto border border-gray-200 shadow-lg p-6 bg-white grid gap-4">
      <h1 className="text-2xl font-bold text-gray-800 text-center">
        Giriş Yap
      </h1>

      {/* Error Message */}
      {message?.message && (
        <div
          className="rounded bg-red-100 p-4 text-red-600"
          role="alert"
          aria-live="assertive"
        >
          {message.message}
        </div>
      )}

      {/* Email Input */}
      <div>
        <InputLabel htmlFor="email" value="Email" />
        <TextInput
          type="email"
          name="email"
          value={email}
          className="mt-1 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          autoComplete="email"
          isFocused
          placeholder="Email adresinizi giriniz"
          onChange={(e) => setEmail(e.target.value)}
        />
        {formErrors.email && (
          <p className="text-red-600 text-sm mt-2">{formErrors.email}</p>
        )}
        <InputError messages={message?.errors?.email} className="mt-2" />
      </div>

      {/* Password Input */}
      <div>
        <InputLabel htmlFor="password" value="Şifre" />
        <div className="relative">
          <TextInput
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            className="mt-1 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            autoComplete="current-password"
            placeholder="Şifrenizi giriniz"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Şifreyi Gizle" : "Şifreyi Göster"}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
        {formErrors.password && (
          <p className="text-red-600 text-sm mt-2">{formErrors.password}</p>
        )}
        <InputError messages={message?.errors?.password} className="mt-2" />
      </div>

      {/* Remember Me Checkbox */}
      <div className="mt-4">
        <label className="flex items-center">
          <Checkbox
            name="remember"
            checked={remember}
            onChange={(e) => {
              setRemember(e.target.checked);
              localStorage.setItem("rememberMe", e.target.checked.toString());
            }}
            innerRef={checkBtn}
          />
          <span className="ml-2 text-sm text-gray-700">Beni Hatırla</span>
        </label>
      </div>

      {/* Forgot Password and Submit */}
      <div className="flex items-center justify-between mt-4">
        <Link
          to={url("Auth.Forgot-Password")}
          className="text-sm text-indigo-600 hover:text-indigo-800 focus:ring focus:ring-indigo-500 rounded"
        >
          Şifrenizi mi unuttunuz?
        </Link>
        <PrimaryButton
          className="ml-4"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? (
            <span className="flex items-center gap-2">
              <FontAwesomeIcon icon={faSpinner} spin />
              Giriş Yapılıyor...
            </span>
          ) : (
            "Giriş Yap"
          )}
        </PrimaryButton>
      </div>
    </div>
  );
}
