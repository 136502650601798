import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney, url } from "../../utils";
import { Link } from "react-router-dom";
import { CourseDto } from "@/data/dtos/course.dto";

export default function Cart() {
  const cart = useSelector((state: any) => state.cart);
  const [showCartItems, setShowCartItems] = useState(false);
  return (
    <button
      className="relative"
      onMouseEnter={() => setShowCartItems(true)}
      onMouseLeave={() => setShowCartItems(false)}
    >
      <div className="relative inline-flex items-center p-3 text-sm font-medium text-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300">
        <FontAwesomeIcon
          className="text-blue-500 hover:text-blue-700 w-6 h-6"
          icon={faCartShopping}
        />
        <span className="sr-only">Notifications</span>
        {cart?.items && (
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -end-1">
            {cart?.items?.length}
          </div>
        )}
      </div>

      {/* Cart items list */}
      {showCartItems && (
        <div className="absolute z-50 w-72 bg-white p-4 rounded-lg shadow-lg top-full right-0 border border-gray-200">
          <h2 className="text-lg font-semibold mb-2 text-gray-700">Sepetim</h2>
          <hr className="mb-3" />
          <ul className="max-h-48 overflow-y-auto">
            {cart?.items?.map((item: CourseDto, index: number) => (
              <li key={index} className="p-2 border-b last:border-b-0">
                <div className="flex gap-3 items-center">
                  <div className="w-12 h-12 rounded overflow-hidden border border-gray-300">
                    <img
                      src={item.images?.[0]?.url ?? ""}
                      alt={item.images?.[0]?.altText ?? ""}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-sm font-medium text-gray-800">
                      {item.title}
                    </h2>
                    <h3 className="text-sm font-semibold text-blue-500">
                      <span>
                        {formatMoney(
                          item.price -
                            item.price * (item.discountPercentage / 100)
                        )}
                      </span>
                    </h3>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-3 text-center">
            <Link
              to={url("Cart")}
              className="text-blue-500 hover:text-blue-600 font-medium text-sm"
            >
              Sepet Detaylarım
            </Link>
          </div>
        </div>
      )}
    </button>
  );
}
