import React from "react";

export default function TeacherCvDisplay({ teacher }) {
  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
      <header className="text-3xl font-bold mb-8 text-center text-gray-800">
        Eğitmen Özgeçmişi
      </header>

      <div className="max-w-4xl mx-auto space-y-8">
        {/* Eğitmen Bilgisi */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">
            Eğitmen Bilgisi
          </h2>
          <label className="block text-gray-600 text-center">
            Adı Soyadı:
            <p className="mt-1 text-gray-800 font-medium">
              {teacher?.name} {teacher?.surname}
            </p>
          </label>
          <label className="block mt-4 text-gray-600">
            Biyografi:
            <p className="mt-1 text-gray-500 italic">{teacher?.cv?.bio}</p>
          </label>
        </div>

        {/* Eğitmen Yetenekleri */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            Eğitmen Yetenekleri
          </h3>
          <label className="block text-gray-600 mb-2">Yetenekler:</label>
          <div className="flex flex-wrap gap-2">
            {teacher?.cv?.skills?.map((skill) => (
              <span
                key={skill}
                className="px-3 py-1 bg-blue-100 text-blue-700 text-sm font-medium rounded-full"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>

        {/* Eğitmen Dilleri */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            Eğitmen Dilleri
          </h3>
          <label className="block text-gray-600 mb-2">Konuştuğu Diller:</label>
          <div className="flex flex-wrap gap-2">
            {teacher?.cv?.languages?.map((language) => (
              <span
                key={language}
                className="px-3 py-1 bg-green-100 text-green-700 text-sm font-medium rounded-full"
              >
                {language}
              </span>
            ))}
          </div>
        </div>

        {/* Eğitmen Eğitim Düzeyi */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            Eğitmen Eğitim Düzeyi
          </h3>
          <label className="block text-gray-600 mb-2">Eğitim Bilgileri:</label>
          <div className="space-y-4">
            {teacher?.cv?.education?.map((education) => (
              <div
                key={education._id}
                className="p-4 bg-gray-100 rounded-md shadow-sm"
              >
                <label className="block text-gray-800 font-medium">
                  <strong>Kuruluş:</strong> {education.institution}
                </label>
                <p className="text-gray-600">
                  <strong>Lisans:</strong> {education.degree}
                </p>
                <p className="text-sm text-gray-500">
                  <strong>Açıklama:</strong> {education.description}
                </p>
                <p className="text-sm text-gray-400">
                  {new Date(education.startDate).toLocaleDateString("tr-TR")}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Eğitmen İş Tecrübeleri */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            Eğitmen İş Tecrübeleri
          </h3>
          <label className="block text-gray-600 mb-2">Tecrübeler:</label>
          <div className="space-y-4">
            {teacher?.cv?.experience?.map((experience) => (
              <div
                key={experience._id}
                className="p-4 bg-gray-100 rounded-md shadow-sm"
              >
                <label className="block text-gray-800 font-medium">
                  <strong>Dal:</strong> {experience.title}
                </label>
                <p className="text-gray-600">
                  <strong>Kuruluş / Şirket</strong> {experience.company}
                </p>
                <p className="text-sm text-gray-500">
                  <strong>Açıklama:</strong>
                  {experience.description}
                </p>
                <p className="text-sm text-gray-400">
                  {new Date(experience.startDate).toLocaleDateString("tr-TR")}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Eğitmen Sertifikaları */}
        <div className="bg-white p-6 rounded-lg shadow-md border">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            Eğitmen Sertifikaları
          </h3>
          <label className="block text-gray-600 mb-2">Sertifikalar:</label>
          <div className="space-y-4">
            {teacher?.cv?.certificates?.map((certificate) => (
              <div
                key={certificate._id}
                className="p-4 bg-gray-100 rounded-md shadow-sm"
              >
                <label className="block text-gray-800 font-medium">
                  <strong>Sertifika Dalı:</strong>
                  {certificate.name}
                </label>
                <p className="text-gray-600">
                  <strong>Kuruluş:</strong>
                  {certificate.institution}
                </p>
                <p className="text-sm text-gray-400">
                  {new Date(certificate.date).toLocaleDateString("tr-TR")}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
