import { getAll, getTeachers, remove } from "@/actions/users.actions";
import Table from "@/components/core/Table";
import OptionsMenu from "@/components/core/TableOptionMenu";
import { formatMoney, url } from "@/utils";
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function TeacherList() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { users } = useSelector((state: any) => state.users);
  console.log(users);

  useEffect(() => {
    dispatch(getTeachers() as any);
  }, [dispatch]);

  const handleDelete = async (id) => {
    dispatch(remove(id) as any);
  };

  const pageSizeOptions = [25, 50, 100];

  const columns = [
    {
      field: "_id",
      headerName: "Teacher Id",
      width: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
    },

    // 'active',
    //   'inactive',
    //   'banned',
    //   'deleted',
    //   'pending',
    //   'verified',
    //   'unverified',
    //   'rejected',
    {
      field: "status",
      headerName: "Status",
      width: 130,
      valueGetter: (param: any) => {
        switch (param) {
          case "active":
            return "Aktif";
          case "inactive":
            return "İnaktif";
          case "banned":
            return "Banlandı";
          case "deleted":
            return "Silindi";
          case "pending":
            return "Bekliyor";
          case "verified":
            return "Onaylandı";
          case "unverified":
            return "Onay Bekliyor";
          case "rejected":
            return "Reddedildi";
          default:
            return "N/A";
        }
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // Prevent row selection on click
          // You can manage the menu state in the parent or directly in the component
        };

        return (
          <Stack direction="row" spacing={1}>
            <OptionsMenu
              id={params.row._id}
              onEdit={() => {
                navigation(url("Admin.Users.Edit", { id: params.row._id }));
              }}
              onView={() => {
                navigation(url("Admin.Users.View", { id: params.row._id }));
              }}
              onDelete={handleDelete}
              onOpenMenu={handleMenuOpen} // Pass the handler to OptionsMenu
            />
          </Stack>
        );
      },
    },
  ];

  if (users?.length > 0) {
    return (
      <div className="w-full bg-white">
        <DataGrid
          rows={users}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSizeOptions[0] },
            },
          }}
          getRowId={(row) => row._id} // Benzersiz ID için _id kullan
        />
      </div>
    );
  } else {
    return <div>Henüz Öğretmen Mevcut değil</div>;
  }
}
