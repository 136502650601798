import { getAll } from "@/actions/setting.action";
import { formatMoney } from "@/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useSettingsData = () => {
  const dispatch = useDispatch();
  const { settings, status } = useSelector((state: any) => state.settings);

  const packages =
    settings?.find((setting) => setting.key === "packages")?.value || [];
  const phones =
    settings?.find((setting) => setting.key === "phones")?.value || [];

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [status, dispatch]);

  return { packages, phones };
};

const PackageCard = ({ item, index, phones }) => {
  const isEven = index % 2 === 0;
  const bgClass = isEven
    ? "bg-green-100 hover:scale-105"
    : "bg-[#FADA7A] hover:scale-110 text-secondary";
  const textClass = isEven ? "text-gray-800" : "text-secondary";

  return (
    <div
      key={index}
      className={`relative w-full md:w-1/2 lg:w-1/4 shadow-xl rounded-2xl overflow-hidden transition-transform ${bgClass}`}
    >
      <div className="p-6 space-y-6">
        <h3 className={`text-2xl font-semibold text-center ${textClass}`}>
          {item.title}
        </h3>
        <div className="flex justify-center ">
          <span className={`text-4xl font-semibold ${textClass}`}>
            {formatMoney(item.price)}
          </span>
        </div>
        <ul className="space-y-4 text-sm text-left">
          {item.items?.map((desc, idx) => (
            <li
              key={idx}
              className={`flex items-start space-x-2 w-full ${textClass}`}
            >
              <span className="text-red-500 font-semibold">&#10003;</span>
              <div
                className="w-full"
                dangerouslySetInnerHTML={{ __html: desc }}
              ></div>
            </li>
          ))}
        </ul>
        <div className={`text-center text-sm mt-4 pb-12 h-[100%] ${textClass}`}>
          <span>Bize Ulaşın:</span>
          <div className="flex flex-wrap gap-4 p-4 items-center justify-center">
            {phones.map((phone, ix) => (
              <a
                key={ix}
                href={`tel:+90${phone}`}
                className="text-red-600 font-bold"
              >
                {phone}
              </a>
            ))}
          </div>
        </div>
      </div>
      <button
        type="button"
        title="Submit"
        className="py-3 px-6 w-5/6 text-center rounded-xl bg-red-600 text-white font-semibold transition hover:bg-red-700 absolute bottom-4 left-1/2 transform -translate-x-1/2"
      >
        Bize Mail Gönderin
      </button>
    </div>
  );
};

export default function Pricing() {
  const { packages, phones } = useSettingsData();

  return (
    <section className="overflow-hidden">
      <div className="bg-gradient-to-b from-secondary-background to-purple-200">
        <div className="container m-auto px-6 py-20 md:px-12 lg:px-20">
          <div className="m-auto text-center lg:w-8/12 xl:w-7/12">
            <h2 className="text-4xl md:text-5xl lg:text-6xl bg-secondary inline-block text-transparent bg-clip-text">
              Abonelik Fırsatları
            </h2>
          </div>
          <div className="flex flex-wrap justify-center gap-8 mt-12">
            {packages.length > 0 &&
              packages
                .slice()
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <PackageCard
                    key={index}
                    item={item}
                    index={index}
                    phones={phones}
                  />
                ))}
          </div>
        </div>
      </div>
    </section>
  );
}
