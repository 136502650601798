import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, sales } from "../../../actions/courses.actions";
import { getAll as getAllCategories } from "../../../actions/category.actions";
import Accordion from "../../../components/core/Accordion";
import Slider from "@mui/material/Slider";
import { formatMoney } from "../../../utils";
import Card from "@/components/core/Card";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import StarRating from "@/components/Rating";

const ITEMS_PER_PAGE = 8; // Customize the number of items per page

export default function Courses() {
  const dispatch = useDispatch();
  const { categories } = useSelector((state: any) => state.categories);
  const { courses } = useSelector((state: any) => state.courses);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [sortBy, setSortBy] = useState("price"); // Sorting state

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 10000]);
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  const languages = ["Türkçe", "English"];
  const levels = [
    { label: "Başlık", value: "Beginner" },
    { label: "Orta", value: "Intermediate" },
    { label: "İleri Düzey", value: "Advanced" },
  ];

  useEffect(() => {
    setLoading(true);
    Promise.all([dispatch(sales() as any), dispatch(getAllCategories() as any)])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  const filteredCourses = courses?.filter(
    (course: any) =>
      course.title
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchQuery
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        ) ||
      course.description
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchQuery
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
  );

  const sortedCourses = [...filteredCourses].sort((a, b) => {
    if (sortBy === "price") return a.price - b.price;
    if (sortBy === "popularity") return b.popularity - a.popularity;
    if (sortBy === "auto") return b._id - a._id;
    return 0;
  });

  const totalPages = Math.ceil((sortedCourses?.length || 0) / ITEMS_PER_PAGE);
  const paginatedCourses = sortedCourses?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguages((prev) =>
      prev.includes(language)
        ? prev.filter((lang) => lang !== language)
        : [...prev, language]
    );
  };

  const handleLevelChange = (level: string) => {
    setSelectedLevels((prev) =>
      prev.includes(level)
        ? prev.filter((lvl) => lvl !== level)
        : [...prev, level]
    );
  };

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue)) {
      setPriceRange([newValue[0], newValue[1]]);
    }
  };

  const handleRatingChange = (rating: number) => {
    setSelectedRating(rating);
  };

  const applyFilters = () => {
    const filters = {
      categories: selectedCategories,
      languages: selectedLanguages,
      levels: selectedLevels,
      priceRange,
      rating: selectedRating,
    };

    dispatch(filter(filters) as any).catch(() => {});
  };

  return (
    <div className="min-h-screen bg-gray-100 pt-4">
      <div className="container mx-auto flex flex-col lg:flex-row gap-6 py-6">
        {/* Sidebar */}
        <aside className="w-full lg:w-1/4 bg-white rounded-lg shadow p-6 space-y-2">
          <Accordion title="Kategoriler">
            {categories?.map((category: any) => (
              <div key={category._id} className="flex items-center gap-4">
                <input
                  type="checkbox"
                  id={category._id}
                  checked={selectedCategories.includes(category._id)} // Ensure this matches your state
                  onChange={() => handleCategoryChange(category._id)}
                />
                <label htmlFor={category._id} className="text-gray-100">
                  {category.name}
                </label>
              </div>
            ))}
          </Accordion>

          <Accordion title="Diller">
            {languages.map((language, index) => (
              <div key={index} className="flex items-center gap-4">
                <input
                  type="checkbox"
                  id={language}
                  checked={selectedLanguages.includes(language)} // Ensure this matches your state
                  onChange={() => handleLanguageChange(language)}
                />
                <label htmlFor={language} className="text-gray-100">
                  {language}
                </label>
              </div>
            ))}
          </Accordion>

          <Accordion title="Atölye Düzeyleri">
            {levels.map((level, index) => (
              <div key={index} className="flex items-center gap-4">
                <input
                  type="checkbox"
                  id={level.value}
                  checked={selectedLevels.includes(level.value)} // Ensure this matches your state
                  onChange={() => handleLevelChange(level.value)}
                />
                <label htmlFor={level.value} className="text-gray-100">
                  {level.label}
                </label>
              </div>
            ))}
          </Accordion>

          <Accordion title="Fiyat Aralığı">
            <div className="space-y-2">
              <Slider
                value={priceRange}
                max={10000}
                onChange={handlePriceRangeChange}
                valueLabelDisplay="auto"
              />
              <div className="flex justify-between text-gray-100">
                <span>{formatMoney(priceRange[0])}</span>
                <span>{formatMoney(priceRange[1])}</span>
              </div>
            </div>
          </Accordion>

          <Accordion title="Yorumlar">
            <StarRating maxStars={5} onRatingSelect={handleRatingChange} />
          </Accordion>

          <div className="px-1 box-border w-full">
            <button
              onClick={applyFilters}
              className="w-full ps-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-lg transition text-left overflow-hidden"
              style={{ boxSizing: "border-box", maxWidth: "100%" }}
            >
              Filtreleri uygula
            </button>
          </div>
        </aside>

        {/* Main Content */}
        <main className="w-full lg:w-3/4 space-y-6">
          {/* Search and Sort */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 p-4 bg-white rounded-lg shadow">
            <input
              type="text"
              placeholder="Atölye Ara"
              className="flex-grow py-2 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <select
              className="py-2 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="auto">Otomatik filtrele</option>
              <option value="price">Fiyata göre filtrele</option>
              <option value="popularity">Popülerliğe göre filtrele</option>
            </select>
          </div>

          {/* Courses List */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-4">
            {loading
              ? Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                  <div key={index} className="p-4">
                    <Skeleton height={200} />
                    <Skeleton height={24} width="80%" />
                    <Skeleton height={16} width="60%" />
                  </div>
                ))
              : paginatedCourses?.map((course: any) => (
                  <Card key={course._id} course={course} />
                ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center items-center gap-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md disabled:opacity-50"
            >
              Önceki
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 rounded-md ${
                  index + 1 === currentPage
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md disabled:opacity-50"
            >
              Sonraki
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}
