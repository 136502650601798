import { useRoutes } from "react-router-dom";
import routes from "@/Routes";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMeta } from "./context/MetaContext";

function App() {
  const { message } = useSelector((state: any) => state.message);
  const toastOptions = {
    // Define default options
    className: "",
    duration: 5000,
    style: {
      background: "#363636",
      color: "#fff",
    },

    // Default options for specific types
    success: {
      duration: 3000,
      theme: {
        primary: "green",
        secondary: "black",
      },
    },
  };

  useEffect(() => {
    if (typeof message === "string") {
      toast.success(message, toastOptions);
    }
    // Eğer message bir object ise ve error varsa error göster
    if (message && typeof message === "object" && message.errors) {
      toast.error(message.message || "An error occurred", toastOptions); // Hata mesajını göster
    }
  });
  const { meta } = useMeta();

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={toastOptions}
      />
      {useRoutes(routes)}
    </>
  );
}

export default App;
