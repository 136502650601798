import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  defaultOpen?: boolean;
  renderHeader?: (isOpen: boolean) => React.ReactNode;
}

export default function Accordion({
  title,
  children,
  className = "",
  style = {},
  defaultOpen = false,
  renderHeader,
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => setIsOpen((prev) => !prev);

  return (
    <div
      className={`rounded-lg border shadow-md bg-blue-500 text-white ${className}`}
      style={style}
    >
      <button
        id={`accordion-header-${title}`}
        className="flex justify-between items-center w-full p-4 focus:outline-none"
        onClick={toggleAccordion}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") toggleAccordion();
        }}
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${title}`}
      >
        {renderHeader ? renderHeader(isOpen) : <span>{title}</span>}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </button>
      {isOpen && (
        <div
          id={`accordion-content-${title}`}
          role="region"
          aria-labelledby={`accordion-header-${title}`}
          className="pl-6 pr-4 pb-4 overflow-hidden transition-max-height duration-500 ease-in-out"
        >
          {children}
        </div>
      )}
    </div>
  );
}
