import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../../components/Avatar";
import Cart from "../../../../components/cart/Cart";
import ApplicationLogo from "@/components/core/ApplicationLogo";
import { UserContext } from "@/context/UserContext";

export default function StudentNavigation() {
  const [search, setSearch] = useState("");

  const onChangeSarch = (e: any) => {
    setSearch(e.target.value);
    console.log(search);
  };

  return (
    <div>
      <nav className="relative px-6 flex gap-4 justify-between items-center bg-white shadow-lg min-h-24">
        <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />

        <div className="flex gap-8 items-center">
          <Cart />
          <Avatar />
        </div>
      </nav>
    </div>
  );
}
