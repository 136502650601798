import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UserContext } from "@/context/UserContext";
import { CvDto } from "@/data/dtos/cv.dto";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { createCv, updateCv } from "@/actions/authAction";
import authService from "@/services/auth.service";

export default function EditCv() {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const { message } = useSelector((state: any) => state.message);
  if (!userContext) {
    throw new Error("UserContext must be used within a UserProvider");
  }
  const { user } = userContext;
  console.log(user);

  const [cvData, setCvData] = useState<CvDto>({
    user: user?._id!,
    profession: "",
    bio: "",
    skills: [""],
    languages: [""],
    education: [
      {
        institution: "",
        startDate: new Date(),
        degree: "",
        description: "",
      },
    ],
    experience: [
      {
        title: "",
        company: "",
        startDate: new Date(),
        description: "",
      },
    ],
    certificates: [
      {
        name: "",
        institution: "",
        date: new Date(),
      },
    ],
  });

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Hakkınızda",
    "Yetenek Bilgileri",
    "Eğitim Bilgileri",
    "Tecrube Bilgileri",
    "Serfikalarnız",
  ];

  useEffect(() => {
    if (user?.cv) {
      setCvData(user.cv);
    }
  }, [user]);

  const handleInputChange = (field, value) => {
    setCvData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (section, index, field, value) => {
    setCvData((prev) => ({
      ...prev,
      [section]: prev[section]?.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const handeSkillChange = (index, value) => {
    setCvData((prev) => ({
      ...prev,
      skills: prev.skills.map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleAddSkill = () => {
    setCvData((prev) => ({
      ...prev,
      skills: [...prev.skills, ""],
    }));
  };

  const handeLanguageChange = (index, value) => {
    setCvData((prev) => ({
      ...prev,
      languages: prev.languages!.map((item, i) => (i === index ? value : item)),
    }));
  };

  const handleAddLanguage = () => {
    setCvData((prev) => ({
      ...prev,
      languages: [...prev.languages!, ""],
    }));
  };

  const handleAddNestedField = (section) => {
    const newField =
      section === "education"
        ? {
            institution: "",
            startDate: new Date(),
            endDate: new Date(),
            degree: "",
            description: "",
          }
        : {
            name: "",
            institution: "",
            date: new Date(),
          };

    setCvData((prev) => ({
      ...prev,
      [section]: [...prev[section], newField],
    }));
  };

  const handleRemoveNestedField = (section, index) => {
    setCvData((prev) => ({
      ...prev,
      [section]: prev[section].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    const { _id, __v, createdAt, updatedAt, ...filteredData } = cvData;

    console.log("Cleaned Payload:", filteredData);

    try {
      if (_id) {
        dispatch(updateCv(_id, filteredData) as any);
        console.log("CV updated successfully.");
      } else {
        dispatch(createCv(filteredData) as any);
        console.log("CV created successfully.");
      }
    } catch (error: any) {
      console.error(
        "Error submitting CV data:",
        error.response?.data || error.message
      );
      alert("There was an error submitting your CV. Please try again.");
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            {message?.message && (
              <div className="flex flex-col gap-4 rounded bg-red-500 p-4">
                {message.message}
              </div>
            )}
            <TextField
              fullWidth
              label="Uzmanlık alanınız"
              value={cvData.profession}
              onChange={(e) => handleInputChange("profession", e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Biografiniz"
              value={cvData.bio}
              onChange={(e) => handleInputChange("bio", e.target.value)}
              margin="normal"
              multiline
              rows={4}
            />
            <Typography variant="h6" gutterBottom>
              Dil
            </Typography>
            {cvData.languages?.map((language, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  fullWidth
                  label={`Dil ${index + 1}`}
                  value={language}
                  onChange={(e) => handeLanguageChange(index, e.target.value)}
                />
                <Button
                  color="error"
                  onClick={() => handleRemoveNestedField("languages", index)}
                  sx={{ ml: 2 }}
                >
                  Kaldır
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddLanguage()}
              sx={{ mt: 2 }}
            >
              Dil Ekle
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Yetenekleriniz
            </Typography>
            {cvData.skills?.map((skill, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  fullWidth
                  label={`Yetenek ${index + 1}`}
                  value={skill}
                  onChange={(e) => handeSkillChange(index, e.target.value)}
                />
                <Button
                  color="error"
                  onClick={() => handleRemoveNestedField("skills", index)}
                  sx={{ ml: 2 }}
                >
                  Kaldır
                </Button>
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddSkill()}
              sx={{ mt: 2 }}
            >
              Yetenek Ekle
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Eğitim
            </Typography>
            {cvData.education?.map((education, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Düzey"
                    value={education.degree}
                    onChange={(e) =>
                      handleNestedChange(
                        "education",
                        index,
                        "degree",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Kurum"
                    value={education.institution}
                    onChange={(e) =>
                      handleNestedChange(
                        "education",
                        index,
                        "institution",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Başlama Tarihi"
                    value={dayjs(education.startDate)}
                    onChange={(value) =>
                      handleNestedChange("education", index, "startDate", value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Bitiş Tarihi"
                    value={dayjs(education.endDate)}
                    onChange={(value) =>
                      handleNestedChange("education", index, "endDate", value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="error"
                    onClick={() => handleRemoveNestedField("education", index)}
                  >
                    Eğitimi Kaldır
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddNestedField("education")}
              sx={{ mt: 2 }}
            >
              Eğitim Ekle
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Deneyimler
            </Typography>
            {cvData.experience?.map((experince, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Çalıştığınız Departman & Meslek"
                    value={experince.title}
                    onChange={(e) =>
                      handleNestedChange(
                        "experience",
                        index,
                        "title",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Şirket"
                    value={experince.company}
                    onChange={(e) =>
                      handleNestedChange(
                        "experience",
                        index,
                        "company",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Acıklama"
                    value={experince.description}
                    onChange={(e) =>
                      handleNestedChange(
                        "experience",
                        index,
                        "description",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Başlama Tarihi"
                    value={dayjs(experince.startDate)}
                    onChange={(value) =>
                      handleNestedChange(
                        "experience",
                        index,
                        "startDate",
                        value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Bitiş Tarihi"
                    value={dayjs(experince.endDate)}
                    onChange={(value) =>
                      handleNestedChange("experience", index, "endDate", value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="error"
                    onClick={() => handleRemoveNestedField("experience", index)}
                  >
                    Deneyimi Kaldır
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddNestedField("experience")}
              sx={{ mt: 2 }}
            >
              Deneyim Ekle
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Sertifiklar
            </Typography>
            {cvData.certificates?.map((cert, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Sertifika Adı"
                    value={cert.name}
                    onChange={(e) =>
                      handleNestedChange(
                        "certificates",
                        index,
                        "name",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Kurum"
                    value={cert.institution}
                    onChange={(e) =>
                      handleNestedChange(
                        "certificates",
                        index,
                        "institution",
                        e.target.value
                      )
                    }
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Sertifika Tarihi"
                    value={dayjs(cert.date)}
                    onChange={(value) =>
                      handleNestedChange("certificates", index, "date", value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="error"
                    onClick={() =>
                      handleRemoveNestedField("certificates", index)
                    }
                  >
                    Kaldır
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button
              variant="outlined"
              onClick={() => handleAddNestedField("certificates")}
              sx={{ mt: 2 }}
            >
              Sertifika ekle
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 800, mx: "auto", mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          CV Güncelleme
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prev) => prev - 1)}
          >
            Önceki Adım
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              activeStep === steps.length - 1
                ? handleSubmit()
                : setActiveStep((prev) => prev + 1)
            }
          >
            {activeStep === steps.length - 1 ? "Onayla" : "Sonraki Adım"}
          </Button>
        </Box>
      </Paper>
    </LocalizationProvider>
  );
}
