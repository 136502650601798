import sectionsService from "@/services/sections.service";
import { setError, setMessage } from "./message";

export const create = (payload: any) => async (dispatch: any) => {
  await sectionsService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: "CREATE_SECTION_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "CREATE_SECTION_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  await sectionsService
    .getAll()
    .then((response: any) => {
      dispatch({ type: "FETCH_SECTIONS_SUCCESS", payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "FETCH_SECTIONS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (id: string) => async (dispatch: any) => {
  await sectionsService
    .get(id)
    .then((response: any) => {
      dispatch({ type: "FETCH_SECTION_SUCCESS", payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "FETCH_SECTION_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update = (id: string, payload: any) => async (dispatch: any) => {
  await sectionsService
    .update(id, payload)
    .then((response: any) => {
      dispatch({ type: "UPDATE_SECTION_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "UPDATE_SECTION_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const remove = (id: string) => async (dispatch: any) => {
  await sectionsService
    .remove(id)
    .then((response: any) => {
      dispatch({ type: "REMOVE_SECTION_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "REMOVE_SECTION_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getSectionsByCourse = (id: string) => async (dispatch: any) => {
  await sectionsService
    .getSectionsByCourse(id)
    .then((response: any) => {
      dispatch({ type: "FETCH_SECTIONS_SUCCESS", payload: response.data.data });
      return Promise.resolve(response);
    })
    .catch((err: any) => {
      dispatch({ type: "FETCH_SECTIONS_FAILED" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
