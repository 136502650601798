interface SettingsState {
  settings: any[];
  currentSetting: any;
  listStatus: string;
  status: string;
}

const initialState: SettingsState = {
  settings: [],
  currentSetting: null,
  listStatus: "idle",
  status: "idle",
};

export default function settingsReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_SETTINGS_SUCCESS":
      return {
        ...state,
        settings: payload,
        listStatus: "success",
      };
    case "FETCH_SETTING_SUCCESS":
      return {
        ...state,
        currentSetting: payload,
        status: "success",
      };
    case "CREATE_SETTING_SUCCESS":
      return {
        ...state,
        settings: [...state.settings, payload],
        status: "success",
      };
    case "UPDATE_SETTING_SUCCESS":
      return {
        ...state,
        settings: state.settings.map((setting: any) =>
          setting._id === payload._id ? payload : setting
        ),
        currentSetting: payload,
        status: "success",
      };
    case "DELETE_SETTING_SUCCESS":
      return {
        ...state,
        settings: state.settings.filter(
          (setting: any) => setting._id !== payload._id
        ),
        status: "success",
      };

    case "FETCH_SETTINGS_FAILED":
    case "FETCH_SETTING_FAILED":
    case "CREATE_SETTING_FAILED":
    case "UPDATE_SETTING_FAILED":
    case "DELETE_SETTING_FAILED":
      return {
        ...state,
        status: "failed",
      };

    default:
      return {
        ...state,
        status: "idle",
      };
  }
}
