import { getAll } from "@/actions/sliders.action";
import { getAll as getAllCourses } from "@/actions/courses.actions";
import React, { startTransition, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAll as getAllCategories } from "@/actions/category.actions";
import CategoryButton from "@/components/core/home/CategoryButton";
import Pricing from "@/components/core/home/Pricing";
import { SlideShow } from "@/components/core/home/SlideShow";
import CourseCard from "@/components/CourseCard";
import { useTranslation } from "react-i18next";
import CardSlider from "@/components/home/CardSlider";
import { UserContext } from "@/context/UserContext";
import { url } from "@/utils";
import FictionalApplyForm from "@/components/home/FictionalApplyForm";
import { Helmet } from "react-helmet-async";
import { useMeta } from "@/context/MetaContext";
import HomeCategorySelection from "@/components/home/HomeCategorySelection";

const Home = () => {
  const dispatch = useDispatch();
  const { setMeta } = useMeta();
  const { sliders, status } = useSelector((state: any) => state.sliders);
  const categories = useSelector((state: any) => state.categories);
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const userContex = useContext(UserContext);
  const { courses, listStatus: courseListStatus } = useSelector(
    (state: any) => state.courses
  );

  if (!userContex) {
    throw new Error("UserContext is null");
  }

  const { user } = userContex;

  function handleChangeCategory(index: number) {
    setSelectedCourse(categories.categories[index]);
  }

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAll() as any);
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (categories.status === "idle") {
      dispatch(getAllCategories() as any);
    }
    setSelectedCourse(categories?.categories[0]);
  }, [dispatch, categories.status, categories.categories]);

  useEffect(() => {
    if (courseListStatus === "idle") {
      dispatch(getAllCourses() as any);
    }
  }, [courseListStatus, dispatch]);

  useEffect(() => {
    setMeta({
      title: "Ana Sayfa",
      description: "Ana sayfa açıklaması",
    });
  }, [setMeta]);

  if (!user) {
    return (
      <div>
        <Helmet>
          <title>Home</title>
          <meta name="description" content="From Helmet" />
        </Helmet>

        {sliders?.length > 0 && (
          <SlideShow slides={sliders[0]} interval={5000} />
        )}

        <HomeCategorySelection
          categories={categories}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          handleChangeCategory={handleChangeCategory}
        />

        <div className="container mx-auto mt-20">
          {/* <!-- Hero Section --> */}
          <section className="bg-[#FADA7A] my-20  shadow rounded">
            <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
              <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 w-full">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/Business_SVG.svg`}
                  alt=""
                  className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 w-full"
                />
              </div>
              <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                <h1 className="text-5xl font-bold leading-none sm:text-6xl text-secondary">
                  Öğrenmeye Başlayın!
                </h1>
                <p className="mt-6 mb-8 text-lg sm:mb-12">
                  Atölye platformumuzla her yerde ve her zaman öğrenin. Kendi
                  hızınızda ilerleyin ve başarıya ulaşın.
                </p>
                <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
                  <Link
                    rel="noopener noreferrer"
                    to={url("Courses")}
                    className="px-8 py-3 text-lg font-semibold rounded bg-violet-600 text-gray-50"
                  >
                    Atölyelerimizi Keşfedin
                  </Link>
                  <Link
                    rel="noopener noreferrer"
                    to="#pricing"
                    className="px-8 py-3 text-lg font-semibold border rounded border-gray-800"
                  >
                    Abonelikleri İnceleyin!
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Features Section --> */}
        </div>
        <div id="pricing">
          <Pricing />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {sliders?.length > 0 && (
          <SlideShow slides={sliders[0]} interval={5000} />
        )}

        <HomeCategorySelection
          categories={categories}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          handleChangeCategory={handleChangeCategory}
        />

        <section id="most-popular" className="container mx-auto py-20">
          <h2 className="text-2xl font-semibold mb-4"> En Popüler Atölyeler</h2>
          <CardSlider selectedCourse={{ courses }} />
        </section>
        <section id="most-popular" className="container mx-auto py-20">
          <h2 className="text-2xl font-semibold mb-4">
            Sizin İçin Önerilenler
          </h2>

          <CardSlider selectedCourse={{ courses }} />
        </section>
      </div>
    );
  }
};

export default Home;
