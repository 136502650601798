import { useEffect, useState } from "react";

interface Slide {
  title: string;
  description: string;
  image: { url: string; altText: string };
}

interface SlideShowProps {
  slides: {
    title: string;
    description: string;
    items: Slide[];
  };
  interval?: number;
  id?: string;
}

const SlideShow: React.FC<SlideShowProps> = ({
  slides,
  interval = 3000,
  id,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatic slide transition
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.items.length);
    }, interval);

    return () => clearInterval(slideInterval); // Cleanup interval
  }, [interval, slides.items.length]);

  // Manual slide change
  const changeSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div
      id={id}
      className="relative container mx-auto h-[20rem] overflow-hidden bg-gray-100"
    >
      {/* Slide Display */}
      <div
        className="absolute inset-0 transition-transform duration-700 ease-in-out flex"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.items.map((slide, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${slide.image.url})` }}
            aria-label={slide.image.altText}
          >
            <div className="relative p-6 bg-black bg-opacity-50 text-white w-full h-full flex flex-col justify-end">
              <h2 className="text-2xl font-bold mb-2">{slide.title}</h2>
              <p className="text-lg">{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Previous Slide Button */}
      <button
        onClick={() =>
          changeSlide(
            currentIndex === 0 ? slides.items.length - 1 : currentIndex - 1
          )
        }
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
        aria-label="Previous Slide"
      >
        ❮
      </button>

      {/* Next Slide Button */}
      <button
        onClick={() => changeSlide((currentIndex + 1) % slides.items.length)}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition focus:outline-none focus:ring-2 focus:ring-gray-400"
        aria-label="Next Slide"
      >
        ❯
      </button>

      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.items.map((_, index) => (
          <button
            key={index}
            onClick={() => changeSlide(index)}
            className={`w-4 h-4 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 ${
              currentIndex === index
                ? "bg-gray-800"
                : "bg-gray-400 hover:bg-gray-500"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export { SlideShow };
