import { CategoryData } from "@/data/dtos/category.dto";
import categoriesService from "../services/categories.service";
import { setError, setMessage } from "./message";
import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_FAILED,
} from "./type";

export const create = (payload: any) => async (dispatch: any) => {
  await categoriesService
    .create(payload)
    .then((response: any) => {
      dispatch({ type: "CREATE_CATEGORY_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve(response);
    })
    .catch((err) => {
      dispatch({ type: "CREATE_CATEGORY_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const getAll = () => async (dispatch: any) => {
  dispatch({ type: FETCH_CATEGORIES });

  await categoriesService
    .getAll()
    .then((response) => {
      dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: response.data.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: FETCH_FAILED });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const get = (payload: any) => async (dispatch: any) => {
  await categoriesService
    .get(payload)
    .then((response: any) => {
      dispatch({ type: "GET_CATEGORY_SUCCESS", payload: response.data });
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: "GET_CATEGORY_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};

export const update =
  (id: string, payload: CategoryData) => async (dispatch: any) => {
    await categoriesService
      .update(id, payload)
      .then((response: any) => {
        dispatch({ type: "UPDATE_CATEGORY_SUCCESS", payload: response.data });
        dispatch(setMessage(response.data.message, 5000));
        return Promise.resolve(response);
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_CATEGORY_FAIL" });
        dispatch(setError(err.response.data));
        return Promise.reject(new Error(err));
      });
  };

export const remove = (payload: any) => async (dispatch: any) => {
  await categoriesService
    .remove(payload)
    .then((response: any) => {
      dispatch({ type: "REMOVE_CATEGORY_SUCCESS", payload: response.data });
      dispatch(setMessage(response.data.message, 5000));
      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({ type: "REMOVE_CATEGORY_FAIL" });
      dispatch(setError(err.response.data));
      return Promise.reject(new Error(err));
    });
};
